import { addPropertyControls, ControlType } from "framer"
import React, { useState, useEffect, useRef } from "react"

/**
 * Created by Joshua Guo
 *
 * @framerIntrinsicWidth 24
 * @framerIntrinsicHeight 24
 *
 * @framerSupportedLayoutWidth fixed
 * @framerSupportedLayoutHeight fixed
 *
 */

export default function TablerIcons(props) {
    // Fetch icon SVG from UNPKG.
    const url = `https://unpkg.com/@tabler/icons@2.15.0/icons/${props.icon}.svg`
    const [svgElement, setSvgElement] = useState(null)
    const svgRef = useRef(null)

    useEffect(() => {
        fetch(url)
            .then((response) => response.text())
            .then((data) => {
                const parser = new DOMParser()
                const svgDoc = parser.parseFromString(data, "image/svg+xml")
                setSvgElement(svgDoc.documentElement)
            })
    }, [url])

    useEffect(() => {
        if (svgElement) {
            const existingSvgElement = svgRef.current.querySelector("svg")
            if (existingSvgElement) {
                svgRef.current.removeChild(existingSvgElement)
            }
            Object.assign(svgElement.style, {
                strokeWidth: props.strokeWidth,
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
            })
            svgRef.current.appendChild(svgElement)
        }
    }, [svgElement])

    useEffect(() => {
        if (svgElement) {
            Object.assign(svgElement.style, { strokeWidth: props.strokeWidth })
        }
    }, [props.strokeWidth])

    return <div style={{ color: props.color }} ref={svgRef} />
}

TablerIcons.defaultProps = {
    icon: "circle-chevron-right",
    color: "#000000",
    strokeWidth: 2,
}

addPropertyControls(TablerIcons, {
    icon: {
        type: ControlType.Enum,
        defaultValue: TablerIcons.defaultProps.icon,
        description:
            "Find every icon name on the [Tabler Icons Library](https://tabler-icons.io/)",
        options: [
            "123",
            "24-hours",
            "2fa",
            "360-view",
            "360",
            "3d-cube-sphere-off",
            "3d-cube-sphere",
            "3d-rotate",
            "a-b-2",
            "a-b-off",
            "a-b",
            "abacus-off",
            "abacus",
            "abc",
            "access-point-off",
            "access-point",
            "accessible-off-filled",
            "accessible-off",
            "accessible",
            "activity-heartbeat",
            "activity",
            "ad-2",
            "ad-circle-filled",
            "ad-circle-off",
            "ad-circle",
            "ad-filled",
            "ad-off",
            "ad",
            "address-book-off",
            "address-book",
            "adjustments-alt",
            "adjustments-bolt",
            "adjustments-cancel",
            "adjustments-check",
            "adjustments-code",
            "adjustments-cog",
            "adjustments-dollar",
            "adjustments-down",
            "adjustments-exclamation",
            "adjustments-filled",
            "adjustments-heart",
            "adjustments-horizontal",
            "adjustments-minus",
            "adjustments-off",
            "adjustments-pause",
            "adjustments-pin",
            "adjustments-plus",
            "adjustments-question",
            "adjustments-search",
            "adjustments-share",
            "adjustments-star",
            "adjustments-up",
            "adjustments-x",
            "adjustments",
            "aerial-lift",
            "affiliate-filled",
            "affiliate",
            "air-balloon",
            "air-conditioning-disabled",
            "air-conditioning",
            "alarm-filled",
            "alarm-minus-filled",
            "alarm-minus",
            "alarm-off",
            "alarm-plus-filled",
            "alarm-plus",
            "alarm-snooze-filled",
            "alarm-snooze",
            "alarm",
            "album-off",
            "album",
            "alert-circle-filled",
            "alert-circle",
            "alert-hexagon-filled",
            "alert-hexagon",
            "alert-octagon-filled",
            "alert-octagon",
            "alert-small",
            "alert-square-filled",
            "alert-square-rounded-filled",
            "alert-square-rounded",
            "alert-square",
            "alert-triangle-filled",
            "alert-triangle",
            "alien-filled",
            "alien",
            "align-box-bottom-center-filled",
            "align-box-bottom-center",
            "align-box-bottom-left-filled",
            "align-box-bottom-left",
            "align-box-bottom-right-filled",
            "align-box-bottom-right",
            "align-box-center-middle-filled",
            "align-box-center-middle",
            "align-box-left-bottom-filled",
            "align-box-left-bottom",
            "align-box-left-middle-filled",
            "align-box-left-middle",
            "align-box-left-top-filled",
            "align-box-left-top",
            "align-box-right-bottom-filled",
            "align-box-right-bottom",
            "align-box-right-middle-filled",
            "align-box-right-middle",
            "align-box-right-top-filled",
            "align-box-right-top",
            "align-box-top-center-filled",
            "align-box-top-center",
            "align-box-top-left-filled",
            "align-box-top-left",
            "align-box-top-right-filled",
            "align-box-top-right",
            "align-center",
            "align-justified",
            "align-left",
            "align-right",
            "alpha",
            "alphabet-cyrillic",
            "alphabet-greek",
            "alphabet-latin",
            "ambulance",
            "ampersand",
            "analyze-filled",
            "analyze-off",
            "analyze",
            "anchor-off",
            "anchor",
            "angle",
            "ankh",
            "antenna-bars-1",
            "antenna-bars-2",
            "antenna-bars-3",
            "antenna-bars-4",
            "antenna-bars-5",
            "antenna-bars-off",
            "antenna-off",
            "antenna",
            "aperture-off",
            "aperture",
            "api-app-off",
            "api-app",
            "api-off",
            "api",
            "app-window-filled",
            "app-window",
            "apple",
            "apps-filled",
            "apps-off",
            "apps",
            "archive-off",
            "archive",
            "armchair-2-off",
            "armchair-2",
            "armchair-off",
            "armchair",
            "arrow-autofit-content-filled",
            "arrow-autofit-content",
            "arrow-autofit-down",
            "arrow-autofit-height",
            "arrow-autofit-left",
            "arrow-autofit-right",
            "arrow-autofit-up",
            "arrow-autofit-width",
            "arrow-back-up-double",
            "arrow-back-up",
            "arrow-back",
            "arrow-badge-down-filled",
            "arrow-badge-down",
            "arrow-badge-left-filled",
            "arrow-badge-left",
            "arrow-badge-right-filled",
            "arrow-badge-right",
            "arrow-badge-up-filled",
            "arrow-badge-up",
            "arrow-bar-down",
            "arrow-bar-left",
            "arrow-bar-right",
            "arrow-bar-to-down",
            "arrow-bar-to-left",
            "arrow-bar-to-right",
            "arrow-bar-to-up",
            "arrow-bar-up",
            "arrow-bear-left-2",
            "arrow-bear-left",
            "arrow-bear-right-2",
            "arrow-bear-right",
            "arrow-big-down-filled",
            "arrow-big-down-line-filled",
            "arrow-big-down-line",
            "arrow-big-down-lines-filled",
            "arrow-big-down-lines",
            "arrow-big-down",
            "arrow-big-left-filled",
            "arrow-big-left-line-filled",
            "arrow-big-left-line",
            "arrow-big-left-lines-filled",
            "arrow-big-left-lines",
            "arrow-big-left",
            "arrow-big-right-filled",
            "arrow-big-right-line-filled",
            "arrow-big-right-line",
            "arrow-big-right-lines-filled",
            "arrow-big-right-lines",
            "arrow-big-right",
            "arrow-big-up-filled",
            "arrow-big-up-line-filled",
            "arrow-big-up-line",
            "arrow-big-up-lines-filled",
            "arrow-big-up-lines",
            "arrow-big-up",
            "arrow-bounce",
            "arrow-curve-left",
            "arrow-curve-right",
            "arrow-down-bar",
            "arrow-down-circle",
            "arrow-down-left-circle",
            "arrow-down-left",
            "arrow-down-rhombus",
            "arrow-down-right-circle",
            "arrow-down-right",
            "arrow-down-square",
            "arrow-down-tail",
            "arrow-down",
            "arrow-elbow-left",
            "arrow-elbow-right",
            "arrow-fork",
            "arrow-forward-up-double",
            "arrow-forward-up",
            "arrow-forward",
            "arrow-guide",
            "arrow-iteration",
            "arrow-left-bar",
            "arrow-left-circle",
            "arrow-left-rhombus",
            "arrow-left-right",
            "arrow-left-square",
            "arrow-left-tail",
            "arrow-left",
            "arrow-loop-left-2",
            "arrow-loop-left",
            "arrow-loop-right-2",
            "arrow-loop-right",
            "arrow-merge-both",
            "arrow-merge-left",
            "arrow-merge-right",
            "arrow-merge",
            "arrow-move-down",
            "arrow-move-left",
            "arrow-move-right",
            "arrow-move-up",
            "arrow-narrow-down",
            "arrow-narrow-left",
            "arrow-narrow-right",
            "arrow-narrow-up",
            "arrow-ramp-left-2",
            "arrow-ramp-left-3",
            "arrow-ramp-left",
            "arrow-ramp-right-2",
            "arrow-ramp-right-3",
            "arrow-ramp-right",
            "arrow-right-bar",
            "arrow-right-circle",
            "arrow-right-rhombus",
            "arrow-right-square",
            "arrow-right-tail",
            "arrow-right",
            "arrow-rotary-first-left",
            "arrow-rotary-first-right",
            "arrow-rotary-last-left",
            "arrow-rotary-last-right",
            "arrow-rotary-left",
            "arrow-rotary-right",
            "arrow-rotary-straight",
            "arrow-roundabout-left",
            "arrow-roundabout-right",
            "arrow-sharp-turn-left",
            "arrow-sharp-turn-right",
            "arrow-up-bar",
            "arrow-up-circle",
            "arrow-up-left-circle",
            "arrow-up-left",
            "arrow-up-rhombus",
            "arrow-up-right-circle",
            "arrow-up-right",
            "arrow-up-square",
            "arrow-up-tail",
            "arrow-up",
            "arrow-wave-left-down",
            "arrow-wave-left-up",
            "arrow-wave-right-down",
            "arrow-wave-right-up",
            "arrow-zig-zag",
            "arrows-cross",
            "arrows-diagonal-2",
            "arrows-diagonal-minimize-2",
            "arrows-diagonal-minimize",
            "arrows-diagonal",
            "arrows-diff",
            "arrows-double-ne-sw",
            "arrows-double-nw-se",
            "arrows-double-se-nw",
            "arrows-double-sw-ne",
            "arrows-down-up",
            "arrows-down",
            "arrows-exchange-2",
            "arrows-exchange",
            "arrows-horizontal",
            "arrows-join-2",
            "arrows-join",
            "arrows-left-down",
            "arrows-left-right",
            "arrows-left",
            "arrows-maximize",
            "arrows-minimize",
            "arrows-move-horizontal",
            "arrows-move-vertical",
            "arrows-move",
            "arrows-random",
            "arrows-right-down",
            "arrows-right-left",
            "arrows-right",
            "arrows-shuffle-2",
            "arrows-shuffle",
            "arrows-sort",
            "arrows-split-2",
            "arrows-split",
            "arrows-transfer-down",
            "arrows-transfer-up",
            "arrows-up-down",
            "arrows-up-left",
            "arrows-up-right",
            "arrows-up",
            "arrows-vertical",
            "artboard-off",
            "artboard",
            "article-filled-filled",
            "article-off",
            "article",
            "aspect-ratio-filled",
            "aspect-ratio-off",
            "aspect-ratio",
            "assembly-off",
            "assembly",
            "asset",
            "asterisk-simple",
            "asterisk",
            "at-off",
            "at",
            "atom-2-filled",
            "atom-2",
            "atom-off",
            "atom",
            "augmented-reality-2",
            "augmented-reality-off",
            "augmented-reality",
            "award-filled",
            "award-off",
            "award",
            "axe",
            "axis-x",
            "axis-y",
            "baby-bottle",
            "baby-carriage",
            "backhoe",
            "backpack-off",
            "backpack",
            "backspace-filled",
            "backspace",
            "badge-3d",
            "badge-4k",
            "badge-8k",
            "badge-ad",
            "badge-ar",
            "badge-cc",
            "badge-filled",
            "badge-hd",
            "badge-off",
            "badge-sd",
            "badge-tm",
            "badge-vo",
            "badge-vr",
            "badge-wc",
            "badge",
            "badges-filled",
            "badges-off",
            "badges",
            "baguette",
            "ball-american-football-off",
            "ball-american-football",
            "ball-baseball",
            "ball-basketball",
            "ball-bowling",
            "ball-football-off",
            "ball-football",
            "ball-tennis",
            "ball-volleyball",
            "balloon-off",
            "balloon",
            "ballpen-off",
            "ballpen",
            "ban",
            "bandage-filled",
            "bandage-off",
            "bandage",
            "barbell-off",
            "barbell",
            "barcode-off",
            "barcode",
            "barrel-off",
            "barrel",
            "barrier-block-off",
            "barrier-block",
            "baseline-density-large",
            "baseline-density-medium",
            "baseline-density-small",
            "baseline",
            "basket-filled",
            "basket-off",
            "basket",
            "bat",
            "bath-filled",
            "bath-off",
            "bath",
            "battery-1-filled",
            "battery-1",
            "battery-2-filled",
            "battery-2",
            "battery-3-filled",
            "battery-3",
            "battery-4-filled",
            "battery-4",
            "battery-automotive",
            "battery-charging-2",
            "battery-charging",
            "battery-eco",
            "battery-filled",
            "battery-off",
            "battery",
            "beach-off",
            "beach",
            "bed-filled",
            "bed-off",
            "bed",
            "beer-filled",
            "beer-off",
            "beer",
            "bell-bolt",
            "bell-cancel",
            "bell-check",
            "bell-code",
            "bell-cog",
            "bell-dollar",
            "bell-down",
            "bell-exclamation",
            "bell-filled",
            "bell-heart",
            "bell-minus-filled",
            "bell-minus",
            "bell-off",
            "bell-pause",
            "bell-pin",
            "bell-plus-filled",
            "bell-plus",
            "bell-question",
            "bell-ringing-2-filled",
            "bell-ringing-2",
            "bell-ringing-filled",
            "bell-ringing",
            "bell-school",
            "bell-search",
            "bell-share",
            "bell-star",
            "bell-up",
            "bell-x-filled",
            "bell-x",
            "bell-z-filled",
            "bell-z",
            "bell",
            "beta",
            "bible",
            "bike-off",
            "bike",
            "binary-off",
            "binary-tree-2",
            "binary-tree",
            "binary",
            "biohazard-off",
            "biohazard",
            "blade-filled",
            "blade",
            "bleach-chlorine",
            "bleach-no-chlorine",
            "bleach-off",
            "bleach",
            "blockquote",
            "bluetooth-connected",
            "bluetooth-off",
            "bluetooth-x",
            "bluetooth",
            "blur-off",
            "blur",
            "bmp",
            "bold-off",
            "bold",
            "bolt-off",
            "bolt",
            "bomb",
            "bone-off",
            "bone",
            "bong-off",
            "bong",
            "book-2",
            "book-download",
            "book-off",
            "book-upload",
            "book",
            "bookmark-off",
            "bookmark",
            "bookmarks-off",
            "bookmarks",
            "books-off",
            "books",
            "border-all",
            "border-bottom",
            "border-corners",
            "border-horizontal",
            "border-inner",
            "border-left",
            "border-none",
            "border-outer",
            "border-radius",
            "border-right",
            "border-sides",
            "border-style-2",
            "border-style",
            "border-top",
            "border-vertical",
            "bottle-off",
            "bottle",
            "bounce-left",
            "bounce-right",
            "bow",
            "bowl",
            "box-align-bottom-left",
            "box-align-bottom-right",
            "box-align-bottom",
            "box-align-left",
            "box-align-right",
            "box-align-top-left",
            "box-align-top-right",
            "box-align-top",
            "box-margin",
            "box-model-2-off",
            "box-model-2",
            "box-model-off",
            "box-model",
            "box-multiple-0",
            "box-multiple-1",
            "box-multiple-2",
            "box-multiple-3",
            "box-multiple-4",
            "box-multiple-5",
            "box-multiple-6",
            "box-multiple-7",
            "box-multiple-8",
            "box-multiple-9",
            "box-multiple",
            "box-off",
            "box-padding",
            "box-seam",
            "box",
            "braces-off",
            "braces",
            "brackets-contain-end",
            "brackets-contain-start",
            "brackets-contain",
            "brackets-off",
            "brackets",
            "braille",
            "brain",
            "brand-4chan",
            "brand-abstract",
            "brand-adobe",
            "brand-adonis-js",
            "brand-airbnb",
            "brand-airtable",
            "brand-algolia",
            "brand-alipay",
            "brand-alpine-js",
            "brand-amazon",
            "brand-amd",
            "brand-amigo",
            "brand-among-us",
            "brand-android",
            "brand-angular",
            "brand-ao3",
            "brand-appgallery",
            "brand-apple-arcade",
            "brand-apple-podcast",
            "brand-apple",
            "brand-appstore",
            "brand-asana",
            "brand-backbone",
            "brand-badoo",
            "brand-baidu",
            "brand-bandcamp",
            "brand-bandlab",
            "brand-beats",
            "brand-behance",
            "brand-bilibili",
            "brand-binance",
            "brand-bing",
            "brand-bitbucket",
            "brand-blackberry",
            "brand-blender",
            "brand-blogger",
            "brand-booking",
            "brand-bootstrap",
            "brand-bulma",
            "brand-bumble",
            "brand-bunpo",
            "brand-c-sharp",
            "brand-cake",
            "brand-cakephp",
            "brand-campaignmonitor",
            "brand-carbon",
            "brand-cashapp",
            "brand-chrome",
            "brand-citymapper",
            "brand-codecov",
            "brand-codepen",
            "brand-codesandbox",
            "brand-cohost",
            "brand-coinbase",
            "brand-comedy-central",
            "brand-coreos",
            "brand-couchdb",
            "brand-couchsurfing",
            "brand-cpp",
            "brand-crunchbase",
            "brand-css3",
            "brand-ctemplar",
            "brand-cucumber",
            "brand-cupra",
            "brand-cypress",
            "brand-d3",
            "brand-days-counter",
            "brand-dcos",
            "brand-debian",
            "brand-deezer",
            "brand-deliveroo",
            "brand-deno",
            "brand-denodo",
            "brand-deviantart",
            "brand-dingtalk",
            "brand-discord-filled",
            "brand-discord",
            "brand-disney",
            "brand-disqus",
            "brand-django",
            "brand-docker",
            "brand-doctrine",
            "brand-dolby-digital",
            "brand-douban",
            "brand-dribbble-filled",
            "brand-dribbble",
            "brand-drops",
            "brand-drupal",
            "brand-edge",
            "brand-elastic",
            "brand-ember",
            "brand-envato",
            "brand-etsy",
            "brand-evernote",
            "brand-facebook-filled",
            "brand-facebook",
            "brand-figma",
            "brand-finder",
            "brand-firebase",
            "brand-firefox",
            "brand-fiverr",
            "brand-flickr",
            "brand-flightradar24",
            "brand-flipboard",
            "brand-flutter",
            "brand-fortnite",
            "brand-foursquare",
            "brand-framer-motion",
            "brand-framer",
            "brand-funimation",
            "brand-gatsby",
            "brand-git",
            "brand-github-copilot",
            "brand-github-filled",
            "brand-github",
            "brand-gitlab",
            "brand-gmail",
            "brand-golang",
            "brand-google-analytics",
            "brand-google-big-query",
            "brand-google-drive",
            "brand-google-fit",
            "brand-google-home",
            "brand-google-one",
            "brand-google-photos",
            "brand-google-play",
            "brand-google-podcasts",
            "brand-google",
            "brand-grammarly",
            "brand-graphql",
            "brand-gravatar",
            "brand-grindr",
            "brand-guardian",
            "brand-gumroad",
            "brand-hbo",
            "brand-headlessui",
            "brand-hipchat",
            "brand-html5",
            "brand-inertia",
            "brand-instagram",
            "brand-intercom",
            "brand-itch",
            "brand-javascript",
            "brand-juejin",
            "brand-kick",
            "brand-kickstarter",
            "brand-kotlin",
            "brand-laravel",
            "brand-lastfm",
            "brand-letterboxd",
            "brand-line",
            "brand-linkedin",
            "brand-linktree",
            "brand-linqpad",
            "brand-loom",
            "brand-mailgun",
            "brand-mantine",
            "brand-mastercard",
            "brand-mastodon",
            "brand-matrix",
            "brand-mcdonalds",
            "brand-medium",
            "brand-mercedes",
            "brand-messenger",
            "brand-meta",
            "brand-miniprogram",
            "brand-mixpanel",
            "brand-monday",
            "brand-mongodb",
            "brand-my-oppo",
            "brand-mysql",
            "brand-national-geographic",
            "brand-nem",
            "brand-netbeans",
            "brand-netease-music",
            "brand-netflix",
            "brand-nexo",
            "brand-nextcloud",
            "brand-nextjs",
            "brand-nord-vpn",
            "brand-notion",
            "brand-npm",
            "brand-nuxt",
            "brand-nytimes",
            "brand-office",
            "brand-ok-ru",
            "brand-onedrive",
            "brand-onlyfans",
            "brand-open-source",
            "brand-openai",
            "brand-openvpn",
            "brand-opera",
            "brand-pagekit",
            "brand-patreon",
            "brand-paypal-filled",
            "brand-paypal",
            "brand-paypay",
            "brand-peanut",
            "brand-pepsi",
            "brand-php",
            "brand-picsart",
            "brand-pinterest",
            "brand-planetscale",
            "brand-pocket",
            "brand-polymer",
            "brand-powershell",
            "brand-prisma",
            "brand-producthunt",
            "brand-pushbullet",
            "brand-pushover",
            "brand-python",
            "brand-qq",
            "brand-radix-ui",
            "brand-react-native",
            "brand-react",
            "brand-reason",
            "brand-reddit",
            "brand-redhat",
            "brand-redux",
            "brand-revolut",
            "brand-safari",
            "brand-samsungpass",
            "brand-sass",
            "brand-sentry",
            "brand-sharik",
            "brand-shazam",
            "brand-shopee",
            "brand-sketch",
            "brand-skype",
            "brand-slack",
            "brand-snapchat",
            "brand-snapseed",
            "brand-snowflake",
            "brand-socket-io",
            "brand-solidjs",
            "brand-soundcloud",
            "brand-spacehey",
            "brand-speedtest",
            "brand-spotify",
            "brand-stackoverflow",
            "brand-stackshare",
            "brand-steam",
            "brand-storybook",
            "brand-storytel",
            "brand-strava",
            "brand-stripe",
            "brand-sublime-text",
            "brand-sugarizer",
            "brand-supabase",
            "brand-superhuman",
            "brand-supernova",
            "brand-surfshark",
            "brand-svelte",
            "brand-symfony",
            "brand-tabler",
            "brand-tailwind",
            "brand-taobao",
            "brand-ted",
            "brand-telegram",
            "brand-tether",
            "brand-threejs",
            "brand-tidal",
            "brand-tikto-filled",
            "brand-tiktok",
            "brand-tinder",
            "brand-topbuzz",
            "brand-torchain",
            "brand-toyota",
            "brand-trello",
            "brand-tripadvisor",
            "brand-tumblr",
            "brand-twilio",
            "brand-twitch",
            "brand-twitter-filled",
            "brand-twitter",
            "brand-typescript",
            "brand-uber",
            "brand-ubuntu",
            "brand-unity",
            "brand-unsplash",
            "brand-upwork",
            "brand-valorant",
            "brand-vercel",
            "brand-vimeo",
            "brand-vinted",
            "brand-visa",
            "brand-visual-studio",
            "brand-vite",
            "brand-vivaldi",
            "brand-vk",
            "brand-volkswagen",
            "brand-vsco",
            "brand-vscode",
            "brand-vue",
            "brand-walmart",
            "brand-waze",
            "brand-webflow",
            "brand-wechat",
            "brand-weibo",
            "brand-whatsapp",
            "brand-windows",
            "brand-windy",
            "brand-wish",
            "brand-wix",
            "brand-wordpress",
            "brand-xbox",
            "brand-xing",
            "brand-yahoo",
            "brand-yatse",
            "brand-ycombinator",
            "brand-youtube-kids",
            "brand-youtube",
            "brand-zalando",
            "brand-zapier",
            "brand-zeit",
            "brand-zhihu",
            "brand-zoom",
            "brand-zulip",
            "brand-zwift",
            "bread-off",
            "bread",
            "briefcase-off",
            "briefcase",
            "brightness-2",
            "brightness-down",
            "brightness-half",
            "brightness-off",
            "brightness-up",
            "brightness",
            "broadcast-off",
            "broadcast",
            "browser-check",
            "browser-off",
            "browser-plus",
            "browser-x",
            "browser",
            "brush-off",
            "brush",
            "bucket-droplet",
            "bucket-off",
            "bucket",
            "bug-off",
            "bug",
            "building-arch",
            "building-bank",
            "building-bridge-2",
            "building-bridge",
            "building-broadcast-tower",
            "building-carousel",
            "building-castle",
            "building-church",
            "building-circus",
            "building-community",
            "building-cottage",
            "building-estate",
            "building-factory-2",
            "building-factory",
            "building-fortress",
            "building-hospital",
            "building-lighthouse",
            "building-monument",
            "building-pavilion",
            "building-skyscraper",
            "building-stadium",
            "building-store",
            "building-tunnel",
            "building-warehouse",
            "building-wind-turbine",
            "building",
            "bulb-filled",
            "bulb-off",
            "bulb",
            "bulldozer",
            "bus-off",
            "bus-stop",
            "bus",
            "businessplan",
            "butterfly",
            "cactus-off",
            "cactus",
            "cake-off",
            "cake",
            "calculator-off",
            "calculator",
            "calendar-bolt",
            "calendar-cancel",
            "calendar-check",
            "calendar-code",
            "calendar-cog",
            "calendar-dollar",
            "calendar-down",
            "calendar-due",
            "calendar-event",
            "calendar-exclamation",
            "calendar-heart",
            "calendar-minus",
            "calendar-off",
            "calendar-pause",
            "calendar-pin",
            "calendar-plus",
            "calendar-question",
            "calendar-search",
            "calendar-share",
            "calendar-star",
            "calendar-stats",
            "calendar-time",
            "calendar-up",
            "calendar-x",
            "calendar",
            "camera-bolt",
            "camera-cancel",
            "camera-check",
            "camera-code",
            "camera-cog",
            "camera-dollar",
            "camera-down",
            "camera-exclamation",
            "camera-filled",
            "camera-heart",
            "camera-minus",
            "camera-off",
            "camera-pause",
            "camera-pin",
            "camera-plus",
            "camera-question",
            "camera-rotate",
            "camera-search",
            "camera-selfie",
            "camera-share",
            "camera-star",
            "camera-up",
            "camera-x",
            "camera",
            "camper",
            "campfire",
            "candle",
            "candy-off",
            "candy",
            "cane",
            "cannabis",
            "capture-off",
            "capture",
            "car-crane",
            "car-crash",
            "car-off",
            "car-turbine",
            "car",
            "caravan",
            "cardboards-off",
            "cardboards",
            "cards",
            "caret-down",
            "caret-left",
            "caret-right",
            "caret-up",
            "carousel-horizontal",
            "carousel-vertical",
            "carrot-off",
            "carrot",
            "cash-banknote-off",
            "cash-banknote",
            "cash-off",
            "cash",
            "cast-off",
            "cast",
            "cat",
            "category-2",
            "category",
            "ce-off",
            "ce",
            "cell-signal-1",
            "cell-signal-2",
            "cell-signal-3",
            "cell-signal-4",
            "cell-signal-5",
            "cell-signal-off",
            "cell",
            "certificate-2-off",
            "certificate-2",
            "certificate-off",
            "certificate",
            "chair-director",
            "chalkboard-off",
            "chalkboard",
            "charging-pile",
            "chart-arcs-3",
            "chart-arcs",
            "chart-area-filled",
            "chart-area-line-filled",
            "chart-area-line",
            "chart-area",
            "chart-arrows-vertical",
            "chart-arrows",
            "chart-bar-off",
            "chart-bar",
            "chart-bubble-filled",
            "chart-bubble",
            "chart-candle-filled",
            "chart-candle",
            "chart-circles",
            "chart-donut-2",
            "chart-donut-3",
            "chart-donut-4",
            "chart-donut-filled",
            "chart-donut",
            "chart-dots-2",
            "chart-dots-3",
            "chart-dots",
            "chart-grid-dots",
            "chart-histogram",
            "chart-infographic",
            "chart-line",
            "chart-pie-2",
            "chart-pie-3",
            "chart-pie-4",
            "chart-pie-filled",
            "chart-pie-off",
            "chart-pie",
            "chart-ppf",
            "chart-radar",
            "chart-sankey",
            "chart-treemap",
            "check",
            "checkbox",
            "checklist",
            "checks",
            "checkup-list",
            "cheese",
            "chef-hat-off",
            "chef-hat",
            "cherry-filled",
            "cherry",
            "chess-bishop-filled",
            "chess-bishop",
            "chess-filled",
            "chess-king-filled",
            "chess-king",
            "chess-knight-filled",
            "chess-knight",
            "chess-queen-filled",
            "chess-queen",
            "chess-rook-filled",
            "chess-rook",
            "chess",
            "chevron-down-left",
            "chevron-down-right",
            "chevron-down",
            "chevron-left",
            "chevron-right",
            "chevron-up-left",
            "chevron-up-right",
            "chevron-up",
            "chevrons-down-left",
            "chevrons-down-right",
            "chevrons-down",
            "chevrons-left",
            "chevrons-right",
            "chevrons-up-left",
            "chevrons-up-right",
            "chevrons-up",
            "chisel",
            "christmas-tree-off",
            "christmas-tree",
            "circle-0-filled",
            "circle-1-filled",
            "circle-2-filled",
            "circle-3-filled",
            "circle-4-filled",
            "circle-5-filled",
            "circle-6-filled",
            "circle-7-filled",
            "circle-8-filled",
            "circle-9-filled",
            "circle-arrow-down-filled",
            "circle-arrow-down-left-filled",
            "circle-arrow-down-left",
            "circle-arrow-down-right-filled",
            "circle-arrow-down-right",
            "circle-arrow-down",
            "circle-arrow-left-filled",
            "circle-arrow-left",
            "circle-arrow-right-filled",
            "circle-arrow-right",
            "circle-arrow-up-filled",
            "circle-arrow-up-left-filled",
            "circle-arrow-up-left",
            "circle-arrow-up-right-filled",
            "circle-arrow-up-right",
            "circle-arrow-up",
            "circle-caret-down",
            "circle-caret-left",
            "circle-caret-right",
            "circle-caret-up",
            "circle-check-filled",
            "circle-check",
            "circle-chevron-down",
            "circle-chevron-left",
            "circle-chevron-right",
            "circle-chevron-up",
            "circle-chevrons-down",
            "circle-chevrons-left",
            "circle-chevrons-right",
            "circle-chevrons-up",
            "circle-dashed",
            "circle-dot-filled",
            "circle-dot",
            "circle-dotted",
            "circle-filled",
            "circle-half-2",
            "circle-half-vertical",
            "circle-half",
            "circle-key-filled",
            "circle-key",
            "circle-letter-a",
            "circle-letter-b",
            "circle-letter-c",
            "circle-letter-d",
            "circle-letter-e",
            "circle-letter-f",
            "circle-letter-g",
            "circle-letter-h",
            "circle-letter-i",
            "circle-letter-j",
            "circle-letter-k",
            "circle-letter-l",
            "circle-letter-m",
            "circle-letter-n",
            "circle-letter-o",
            "circle-letter-p",
            "circle-letter-q",
            "circle-letter-r",
            "circle-letter-s",
            "circle-letter-t",
            "circle-letter-u",
            "circle-letter-v",
            "circle-letter-w",
            "circle-letter-x",
            "circle-letter-y",
            "circle-letter-z",
            "circle-minus",
            "circle-number-0",
            "circle-number-1",
            "circle-number-2",
            "circle-number-3",
            "circle-number-4",
            "circle-number-5",
            "circle-number-6",
            "circle-number-7",
            "circle-number-8",
            "circle-number-9",
            "circle-off",
            "circle-plus",
            "circle-rectangle-off",
            "circle-rectangle",
            "circle-square",
            "circle-triangle",
            "circle-x-filled",
            "circle-x",
            "circle",
            "circles-filled",
            "circles-relation",
            "circles",
            "circuit-ammeter",
            "circuit-battery",
            "circuit-bulb",
            "circuit-capacitor-polarized",
            "circuit-capacitor",
            "circuit-cell-plus",
            "circuit-cell",
            "circuit-changeover",
            "circuit-diode-zener",
            "circuit-diode",
            "circuit-ground-digital",
            "circuit-ground",
            "circuit-inductor",
            "circuit-motor",
            "circuit-pushbutton",
            "circuit-resistor",
            "circuit-switch-closed",
            "circuit-switch-open",
            "circuit-voltmeter",
            "clear-all",
            "clear-formatting",
            "click",
            "clipboard-check",
            "clipboard-copy",
            "clipboard-data",
            "clipboard-heart",
            "clipboard-list",
            "clipboard-off",
            "clipboard-plus",
            "clipboard-text",
            "clipboard-typography",
            "clipboard-x",
            "clipboard",
            "clock-2",
            "clock-bolt",
            "clock-cancel",
            "clock-check",
            "clock-code",
            "clock-cog",
            "clock-dollar",
            "clock-down",
            "clock-edit",
            "clock-exclamation",
            "clock-filled",
            "clock-heart",
            "clock-hour-1",
            "clock-hour-10",
            "clock-hour-11",
            "clock-hour-12",
            "clock-hour-2",
            "clock-hour-3",
            "clock-hour-4",
            "clock-hour-5",
            "clock-hour-6",
            "clock-hour-7",
            "clock-hour-8",
            "clock-hour-9",
            "clock-minus",
            "clock-off",
            "clock-pause",
            "clock-pin",
            "clock-play",
            "clock-plus",
            "clock-question",
            "clock-record",
            "clock-search",
            "clock-share",
            "clock-shield",
            "clock-star",
            "clock-stop",
            "clock-up",
            "clock-x",
            "clock",
            "clothes-rack-off",
            "clothes-rack",
            "cloud-bolt",
            "cloud-cancel",
            "cloud-check",
            "cloud-code",
            "cloud-cog",
            "cloud-computing",
            "cloud-data-connection",
            "cloud-dollar",
            "cloud-down",
            "cloud-download",
            "cloud-exclamation",
            "cloud-filled",
            "cloud-fog",
            "cloud-heart",
            "cloud-lock-open",
            "cloud-lock",
            "cloud-minus",
            "cloud-off",
            "cloud-pause",
            "cloud-pin",
            "cloud-plus",
            "cloud-question",
            "cloud-rain",
            "cloud-search",
            "cloud-share",
            "cloud-snow",
            "cloud-star",
            "cloud-storm",
            "cloud-up",
            "cloud-upload",
            "cloud-x",
            "cloud",
            "clover-2",
            "clover",
            "clubs-filled",
            "clubs",
            "code-asterix",
            "code-circle-2",
            "code-circle",
            "code-dots",
            "code-minus",
            "code-off",
            "code-plus",
            "code",
            "coffee-off",
            "coffee",
            "coffin",
            "coin-bitcoin",
            "coin-euro",
            "coin-monero",
            "coin-off",
            "coin-pound",
            "coin-rupee",
            "coin-yen",
            "coin-yuan",
            "coin",
            "coins",
            "color-filter",
            "color-picker-off",
            "color-picker",
            "color-swatch-off",
            "color-swatch",
            "column-insert-left",
            "column-insert-right",
            "columns-1",
            "columns-2",
            "columns-3",
            "columns-off",
            "columns",
            "comet",
            "command-off",
            "command",
            "compass-off",
            "compass",
            "components-off",
            "components",
            "cone-2",
            "cone-off",
            "cone",
            "confetti-off",
            "confetti",
            "confucius",
            "container-off",
            "container",
            "contrast-2-off",
            "contrast-2",
            "contrast-off",
            "contrast",
            "cooker",
            "cookie-man",
            "cookie-off",
            "cookie",
            "copy-off",
            "copy",
            "copyleft-filled",
            "copyleft-off",
            "copyleft",
            "copyright-filled",
            "copyright-off",
            "copyright",
            "corner-down-left-double",
            "corner-down-left",
            "corner-down-right-double",
            "corner-down-right",
            "corner-left-down-double",
            "corner-left-down",
            "corner-left-up-double",
            "corner-left-up",
            "corner-right-down-double",
            "corner-right-down",
            "corner-right-up-double",
            "corner-right-up",
            "corner-up-left-double",
            "corner-up-left",
            "corner-up-right-double",
            "corner-up-right",
            "cpu-2",
            "cpu-off",
            "cpu",
            "crane-off",
            "crane",
            "creative-commons-by",
            "creative-commons-nc",
            "creative-commons-nd",
            "creative-commons-off",
            "creative-commons-sa",
            "creative-commons-zero",
            "creative-commons",
            "credit-card-off",
            "credit-card",
            "cricket",
            "crop",
            "cross-filled",
            "cross-off",
            "cross",
            "crosshair",
            "crown-off",
            "crown",
            "crutches-off",
            "crutches",
            "crystal-ball",
            "csv",
            "cube-send",
            "cube-unfolded",
            "cup-off",
            "cup",
            "curling",
            "curly-loop",
            "currency-afghani",
            "currency-bahraini",
            "currency-baht",
            "currency-bitcoin",
            "currency-cent",
            "currency-dinar",
            "currency-dirham",
            "currency-dogecoin",
            "currency-dollar-australian",
            "currency-dollar-brunei",
            "currency-dollar-canadian",
            "currency-dollar-guyanese",
            "currency-dollar-off",
            "currency-dollar-singapore",
            "currency-dollar-zimbabwean",
            "currency-dollar",
            "currency-dong",
            "currency-dram",
            "currency-ethereum",
            "currency-euro-off",
            "currency-euro",
            "currency-forint",
            "currency-frank",
            "currency-guarani",
            "currency-hryvnia",
            "currency-kip",
            "currency-krone-czech",
            "currency-krone-danish",
            "currency-krone-swedish",
            "currency-lari",
            "currency-leu",
            "currency-lira",
            "currency-litecoin",
            "currency-lyd",
            "currency-manat",
            "currency-monero",
            "currency-naira",
            "currency-nano",
            "currency-off",
            "currency-paanga",
            "currency-peso",
            "currency-pound-off",
            "currency-pound",
            "currency-quetzal",
            "currency-real",
            "currency-renminbi",
            "currency-ripple",
            "currency-riyal",
            "currency-rubel",
            "currency-rufiyaa",
            "currency-rupee-nepalese",
            "currency-rupee",
            "currency-shekel",
            "currency-solana",
            "currency-som",
            "currency-taka",
            "currency-tenge",
            "currency-tugrik",
            "currency-won",
            "currency-yen-off",
            "currency-yen",
            "currency-yuan",
            "currency-zloty",
            "currency",
            "current-location-off",
            "current-location",
            "cursor-off",
            "cursor-text",
            "cut",
            "cylinder",
            "dashboard-off",
            "dashboard",
            "database-cog",
            "database-dollar",
            "database-edit",
            "database-exclamation",
            "database-export",
            "database-heart",
            "database-import",
            "database-leak",
            "database-minus",
            "database-off",
            "database-plus",
            "database-search",
            "database-share",
            "database-star",
            "database-x",
            "database",
            "decimal",
            "deer",
            "delta",
            "dental-broken",
            "dental-off",
            "dental",
            "deselect",
            "details-off",
            "details",
            "device-airpods-case",
            "device-airpods",
            "device-analytics",
            "device-audio-tape",
            "device-camera-phone",
            "device-cctv-off",
            "device-cctv",
            "device-computer-camera-off",
            "device-computer-camera",
            "device-desktop-analytics",
            "device-desktop-bolt",
            "device-desktop-cancel",
            "device-desktop-check",
            "device-desktop-code",
            "device-desktop-cog",
            "device-desktop-dollar",
            "device-desktop-down",
            "device-desktop-exclamation",
            "device-desktop-heart",
            "device-desktop-minus",
            "device-desktop-off",
            "device-desktop-pause",
            "device-desktop-pin",
            "device-desktop-plus",
            "device-desktop-question",
            "device-desktop-search",
            "device-desktop-share",
            "device-desktop-star",
            "device-desktop-up",
            "device-desktop-x",
            "device-desktop",
            "device-floppy",
            "device-gamepad-2",
            "device-gamepad",
            "device-heart-monitor-filled",
            "device-heart-monitor",
            "device-imac-bolt",
            "device-imac-cancel",
            "device-imac-check",
            "device-imac-code",
            "device-imac-cog",
            "device-imac-dollar",
            "device-imac-down",
            "device-imac-exclamation",
            "device-imac-heart",
            "device-imac-minus",
            "device-imac-off",
            "device-imac-pause",
            "device-imac-pin",
            "device-imac-plus",
            "device-imac-question",
            "device-imac-search",
            "device-imac-share",
            "device-imac-star",
            "device-imac-up",
            "device-imac-x",
            "device-imac",
            "device-ipad-bolt",
            "device-ipad-cancel",
            "device-ipad-check",
            "device-ipad-code",
            "device-ipad-cog",
            "device-ipad-dollar",
            "device-ipad-down",
            "device-ipad-exclamation",
            "device-ipad-heart",
            "device-ipad-horizontal-bolt",
            "device-ipad-horizontal-cancel",
            "device-ipad-horizontal-check",
            "device-ipad-horizontal-code",
            "device-ipad-horizontal-cog",
            "device-ipad-horizontal-dollar",
            "device-ipad-horizontal-down",
            "device-ipad-horizontal-exclamation",
            "device-ipad-horizontal-heart",
            "device-ipad-horizontal-minus",
            "device-ipad-horizontal-off",
            "device-ipad-horizontal-pause",
            "device-ipad-horizontal-pin",
            "device-ipad-horizontal-plus",
            "device-ipad-horizontal-question",
            "device-ipad-horizontal-search",
            "device-ipad-horizontal-share",
            "device-ipad-horizontal-star",
            "device-ipad-horizontal-up",
            "device-ipad-horizontal-x",
            "device-ipad-horizontal",
            "device-ipad-minus",
            "device-ipad-off",
            "device-ipad-pause",
            "device-ipad-pin",
            "device-ipad-plus",
            "device-ipad-question",
            "device-ipad-search",
            "device-ipad-share",
            "device-ipad-star",
            "device-ipad-up",
            "device-ipad-x",
            "device-ipad",
            "device-landline-phone",
            "device-laptop-off",
            "device-laptop",
            "device-mobile-bolt",
            "device-mobile-cancel",
            "device-mobile-charging",
            "device-mobile-check",
            "device-mobile-code",
            "device-mobile-cog",
            "device-mobile-dollar",
            "device-mobile-down",
            "device-mobile-exclamation",
            "device-mobile-filled",
            "device-mobile-heart",
            "device-mobile-message",
            "device-mobile-minus",
            "device-mobile-off",
            "device-mobile-pause",
            "device-mobile-pin",
            "device-mobile-plus",
            "device-mobile-question",
            "device-mobile-rotated",
            "device-mobile-search",
            "device-mobile-share",
            "device-mobile-star",
            "device-mobile-up",
            "device-mobile-vibration",
            "device-mobile-x",
            "device-mobile",
            "device-nintendo-off",
            "device-nintendo",
            "device-remote",
            "device-sd-card",
            "device-sim-1",
            "device-sim-2",
            "device-sim-3",
            "device-sim",
            "device-speaker-off",
            "device-speaker",
            "device-tablet-bolt",
            "device-tablet-cancel",
            "device-tablet-check",
            "device-tablet-code",
            "device-tablet-cog",
            "device-tablet-dollar",
            "device-tablet-down",
            "device-tablet-exclamation",
            "device-tablet-filled",
            "device-tablet-heart",
            "device-tablet-minus",
            "device-tablet-off",
            "device-tablet-pause",
            "device-tablet-pin",
            "device-tablet-plus",
            "device-tablet-question",
            "device-tablet-search",
            "device-tablet-share",
            "device-tablet-star",
            "device-tablet-up",
            "device-tablet-x",
            "device-tablet",
            "device-tv-off",
            "device-tv-old",
            "device-tv",
            "device-watch-bolt",
            "device-watch-cancel",
            "device-watch-check",
            "device-watch-code",
            "device-watch-cog",
            "device-watch-dollar",
            "device-watch-down",
            "device-watch-exclamation",
            "device-watch-heart",
            "device-watch-minus",
            "device-watch-off",
            "device-watch-pause",
            "device-watch-pin",
            "device-watch-plus",
            "device-watch-question",
            "device-watch-search",
            "device-watch-share",
            "device-watch-star",
            "device-watch-stats-2",
            "device-watch-stats",
            "device-watch-up",
            "device-watch-x",
            "device-watch",
            "devices-2",
            "devices-bolt",
            "devices-cancel",
            "devices-check",
            "devices-code",
            "devices-cog",
            "devices-dollar",
            "devices-down",
            "devices-exclamation",
            "devices-heart",
            "devices-minus",
            "devices-off",
            "devices-pause",
            "devices-pc-off",
            "devices-pc",
            "devices-pin",
            "devices-plus",
            "devices-question",
            "devices-search",
            "devices-share",
            "devices-star",
            "devices-up",
            "devices-x",
            "devices",
            "dialpad-filled",
            "dialpad-off",
            "dialpad",
            "diamond-filled",
            "diamond-off",
            "diamond",
            "diamonds-filled",
            "diamonds",
            "dice-1-filled",
            "dice-1",
            "dice-2-filled",
            "dice-2",
            "dice-3-filled",
            "dice-3",
            "dice-4-filled",
            "dice-4",
            "dice-5-filled",
            "dice-5",
            "dice-6-filled",
            "dice-6",
            "dice-filled",
            "dice",
            "dimensions",
            "direction-horizontal",
            "direction-sign-filled",
            "direction-sign-off",
            "direction-sign",
            "direction",
            "directions-off",
            "directions",
            "disabled-2",
            "disabled-off",
            "disabled",
            "disc-golf",
            "disc-off",
            "disc",
            "discount-2-off",
            "discount-2",
            "discount-check-filled",
            "discount-check",
            "discount-off",
            "discount",
            "divide",
            "dna-2-off",
            "dna-2",
            "dna-off",
            "dna",
            "dog-bowl",
            "dog",
            "door-enter",
            "door-exit",
            "door-off",
            "door",
            "dots-circle-horizontal",
            "dots-diagonal-2",
            "dots-diagonal",
            "dots-vertical",
            "dots",
            "download-off",
            "download",
            "drag-drop-2",
            "drag-drop",
            "drone-off",
            "drone",
            "drop-circle",
            "droplet-bolt",
            "droplet-cancel",
            "droplet-check",
            "droplet-code",
            "droplet-cog",
            "droplet-dollar",
            "droplet-down",
            "droplet-exclamation",
            "droplet-filled-2",
            "droplet-filled",
            "droplet-half-2",
            "droplet-half-filled",
            "droplet-half",
            "droplet-heart",
            "droplet-minus",
            "droplet-off",
            "droplet-pause",
            "droplet-pin",
            "droplet-plus",
            "droplet-question",
            "droplet-search",
            "droplet-share",
            "droplet-star",
            "droplet-up",
            "droplet-x",
            "droplet",
            "e-passport",
            "ear-off",
            "ear",
            "ease-in-control-point",
            "ease-in-out-control-points",
            "ease-in-out",
            "ease-in",
            "ease-out-control-point",
            "ease-out",
            "edit-circle-off",
            "edit-circle",
            "edit-off",
            "edit",
            "egg-cracked",
            "egg-filled",
            "egg-fried",
            "egg-off",
            "egg",
            "eggs",
            "elevator-off",
            "elevator",
            "emergency-bed",
            "empathize-off",
            "empathize",
            "emphasis",
            "engine-off",
            "engine",
            "equal-double",
            "equal-not",
            "equal",
            "eraser-off",
            "eraser",
            "error-404-off",
            "error-404",
            "exchange-off",
            "exchange",
            "exclamation-circle",
            "exclamation-mark-off",
            "exclamation-mark",
            "explicit-off",
            "explicit",
            "exposure-0",
            "exposure-minus-1",
            "exposure-minus-2",
            "exposure-off",
            "exposure-plus-1",
            "exposure-plus-2",
            "exposure",
            "external-link-off",
            "external-link",
            "eye-check",
            "eye-closed",
            "eye-cog",
            "eye-edit",
            "eye-exclamation",
            "eye-filled",
            "eye-heart",
            "eye-off",
            "eye-table",
            "eye-x",
            "eye",
            "eyeglass-2",
            "eyeglass-off",
            "eyeglass",
            "face-id-error",
            "face-id",
            "face-mask-off",
            "face-mask",
            "fall",
            "feather-off",
            "feather",
            "fence-off",
            "fence",
            "fidget-spinner",
            "file-3d",
            "file-alert",
            "file-analytics",
            "file-arrow-left",
            "file-arrow-right",
            "file-barcode",
            "file-broken",
            "file-certificate",
            "file-chart",
            "file-check",
            "file-code-2",
            "file-code",
            "file-database",
            "file-delta",
            "file-description",
            "file-diff",
            "file-digit",
            "file-dislike",
            "file-dollar",
            "file-dots",
            "file-download",
            "file-euro",
            "file-export",
            "file-filled",
            "file-function",
            "file-horizontal",
            "file-import",
            "file-infinity",
            "file-info",
            "file-invoice",
            "file-lambda",
            "file-like",
            "file-minus",
            "file-music",
            "file-off",
            "file-orientation",
            "file-pencil",
            "file-percent",
            "file-phone",
            "file-plus",
            "file-power",
            "file-report",
            "file-rss",
            "file-scissors",
            "file-search",
            "file-settings",
            "file-shredder",
            "file-signal",
            "file-spreadsheet",
            "file-stack",
            "file-star",
            "file-symlink",
            "file-text-ai",
            "file-text",
            "file-time",
            "file-typography",
            "file-unknown",
            "file-upload",
            "file-vector",
            "file-x-filled",
            "file-x",
            "file-zip",
            "file",
            "files-off",
            "files",
            "filter-cog",
            "filter-dollar",
            "filter-edit",
            "filter-minus",
            "filter-off",
            "filter-plus",
            "filter-star",
            "filter-x",
            "filter",
            "filters",
            "fingerprint-off",
            "fingerprint",
            "fire-hydrant-off",
            "fire-hydrant",
            "firetruck",
            "first-aid-kit-off",
            "first-aid-kit",
            "fish-bone",
            "fish-christianity",
            "fish-hook-off",
            "fish-hook",
            "fish-off",
            "fish",
            "flag-2-filled",
            "flag-2-off",
            "flag-2",
            "flag-3-filled",
            "flag-3",
            "flag-filled",
            "flag-off",
            "flag",
            "flame-off",
            "flame",
            "flare",
            "flask-2-off",
            "flask-2",
            "flask-off",
            "flask",
            "flip-flops",
            "flip-horizontal",
            "flip-vertical",
            "float-center",
            "float-left",
            "float-none",
            "float-right",
            "flower-off",
            "flower",
            "focus-2",
            "focus-centered",
            "focus",
            "fold-down",
            "fold-up",
            "fold",
            "folder-bolt",
            "folder-cancel",
            "folder-check",
            "folder-code",
            "folder-cog",
            "folder-dollar",
            "folder-down",
            "folder-exclamation",
            "folder-filled",
            "folder-heart",
            "folder-minus",
            "folder-off",
            "folder-pause",
            "folder-pin",
            "folder-plus",
            "folder-question",
            "folder-search",
            "folder-share",
            "folder-star",
            "folder-symlink",
            "folder-up",
            "folder-x",
            "folder",
            "folders-off",
            "folders",
            "forbid-2",
            "forbid",
            "forklift",
            "forms",
            "fountain-off",
            "fountain",
            "frame-off",
            "frame",
            "free-rights",
            "fridge-off",
            "fridge",
            "friends-off",
            "friends",
            "function-off",
            "function",
            "garden-cart-off",
            "garden-cart",
            "gas-station-off",
            "gas-station",
            "gauge-off",
            "gauge",
            "gavel",
            "gender-agender",
            "gender-androgyne",
            "gender-bigender",
            "gender-demiboy",
            "gender-demigirl",
            "gender-epicene",
            "gender-female",
            "gender-femme",
            "gender-genderfluid",
            "gender-genderless",
            "gender-genderqueer",
            "gender-hermaphrodite",
            "gender-intergender",
            "gender-male",
            "gender-neutrois",
            "gender-third",
            "gender-transgender",
            "gender-trasvesti",
            "geometry",
            "ghost-2-filled",
            "ghost-2",
            "ghost-filled",
            "ghost-off",
            "ghost",
            "gif",
            "gift-card",
            "gift-off",
            "gift",
            "git-branch-deleted",
            "git-branch",
            "git-cherry-pick",
            "git-commit",
            "git-compare",
            "git-fork",
            "git-merge",
            "git-pull-request-closed",
            "git-pull-request-draft",
            "git-pull-request",
            "gizmo",
            "glass-full",
            "glass-off",
            "glass",
            "globe-off",
            "globe",
            "go-game",
            "golf-off",
            "golf",
            "gps",
            "gradienter",
            "grain",
            "graph-off",
            "graph",
            "grave-2",
            "grave",
            "grid-dots",
            "grid-pattern",
            "grill-fork",
            "grill-off",
            "grill-spatula",
            "grill",
            "grip-horizontal",
            "grip-vertical",
            "growth",
            "guitar-pick-filled",
            "guitar-pick",
            "h-1",
            "h-2",
            "h-3",
            "h-4",
            "h-5",
            "h-6",
            "hammer-off",
            "hammer",
            "hand-click",
            "hand-finger-off",
            "hand-finger",
            "hand-grab",
            "hand-little-finger",
            "hand-middle-finger",
            "hand-move",
            "hand-off",
            "hand-ring-finger",
            "hand-rock",
            "hand-sanitizer",
            "hand-stop",
            "hand-three-fingers",
            "hand-two-fingers",
            "hanger-2",
            "hanger-off",
            "hanger",
            "hash",
            "haze",
            "heading-off",
            "heading",
            "headphones-filled",
            "headphones-off",
            "headphones",
            "headset-off",
            "headset",
            "health-recognition",
            "heart-broken",
            "heart-filled",
            "heart-handshake",
            "heart-minus",
            "heart-off",
            "heart-plus",
            "heart-rate-monitor",
            "heart",
            "heartbeat",
            "hearts-off",
            "hearts",
            "helicopter-landing",
            "helicopter",
            "helmet-off",
            "helmet",
            "help-circle-filled",
            "help-circle",
            "help-hexagon-filled",
            "help-hexagon",
            "help-octagon-filled",
            "help-octagon",
            "help-off",
            "help-small",
            "help-square-filled",
            "help-square-rounded-filled",
            "help-square-rounded",
            "help-square",
            "help-triangle-filled",
            "help-triangle",
            "help",
            "hexagon-0-filled",
            "hexagon-1-filled",
            "hexagon-2-filled",
            "hexagon-3-filled",
            "hexagon-3d",
            "hexagon-4-filled",
            "hexagon-5-filled",
            "hexagon-6-filled",
            "hexagon-7-filled",
            "hexagon-8-filled",
            "hexagon-9-filled",
            "hexagon-filled",
            "hexagon-letter-a",
            "hexagon-letter-b",
            "hexagon-letter-c",
            "hexagon-letter-d",
            "hexagon-letter-e",
            "hexagon-letter-f",
            "hexagon-letter-g",
            "hexagon-letter-h",
            "hexagon-letter-i",
            "hexagon-letter-j",
            "hexagon-letter-k",
            "hexagon-letter-l",
            "hexagon-letter-m",
            "hexagon-letter-n",
            "hexagon-letter-o",
            "hexagon-letter-p",
            "hexagon-letter-q",
            "hexagon-letter-r",
            "hexagon-letter-s",
            "hexagon-letter-t",
            "hexagon-letter-u",
            "hexagon-letter-v",
            "hexagon-letter-w",
            "hexagon-letter-x",
            "hexagon-letter-y",
            "hexagon-letter-z",
            "hexagon-number-0",
            "hexagon-number-1",
            "hexagon-number-2",
            "hexagon-number-3",
            "hexagon-number-4",
            "hexagon-number-5",
            "hexagon-number-6",
            "hexagon-number-7",
            "hexagon-number-8",
            "hexagon-number-9",
            "hexagon-off",
            "hexagon",
            "hexagons-off",
            "hexagons",
            "hierarchy-2",
            "hierarchy-3",
            "hierarchy-off",
            "hierarchy",
            "highlight-off",
            "highlight",
            "history-off",
            "history-toggle",
            "history",
            "home-2",
            "home-bolt",
            "home-cancel",
            "home-check",
            "home-cog",
            "home-dollar",
            "home-dot",
            "home-down",
            "home-eco",
            "home-edit",
            "home-exclamation",
            "home-hand",
            "home-heart",
            "home-infinity",
            "home-link",
            "home-minus",
            "home-move",
            "home-off",
            "home-plus",
            "home-question",
            "home-ribbon",
            "home-search",
            "home-share",
            "home-shield",
            "home-signal",
            "home-star",
            "home-stats",
            "home-up",
            "home-x",
            "home",
            "horse-toy",
            "hotel-service",
            "hourglass-empty",
            "hourglass-filled",
            "hourglass-high",
            "hourglass-low",
            "hourglass-off",
            "hourglass",
            "html",
            "http-connect",
            "http-delete",
            "http-get",
            "http-head",
            "http-options",
            "http-path",
            "http-post",
            "http-put",
            "http-trace",
            "ice-cream-2",
            "ice-cream-off",
            "ice-cream",
            "ice-skating",
            "icons-off",
            "icons",
            "id-badge-2",
            "id-badge-off",
            "id-badge",
            "id-off",
            "id",
            "inbox-off",
            "inbox",
            "indent-decrease",
            "indent-increase",
            "infinity-off",
            "infinity",
            "info-circle-filled",
            "info-circle",
            "info-hexagon-filled",
            "info-hexagon",
            "info-octagon-filled",
            "info-octagon",
            "info-small",
            "info-square-filled",
            "info-square-rounded-filled",
            "info-square-rounded",
            "info-square",
            "info-triangle-filled",
            "info-triangle",
            "inner-shadow-bottom-filled",
            "inner-shadow-bottom-left-filled",
            "inner-shadow-bottom-left",
            "inner-shadow-bottom-right-filled",
            "inner-shadow-bottom-right",
            "inner-shadow-bottom",
            "inner-shadow-left-filled",
            "inner-shadow-left",
            "inner-shadow-right-filled",
            "inner-shadow-right",
            "inner-shadow-top-filled",
            "inner-shadow-top-left-filled",
            "inner-shadow-top-left",
            "inner-shadow-top-right-filled",
            "inner-shadow-top-right",
            "inner-shadow-top",
            "input-search",
            "ironing-1",
            "ironing-2",
            "ironing-3",
            "ironing-off",
            "ironing-steam-off",
            "ironing-steam",
            "italic",
            "jacket",
            "jetpack",
            "jewish-star-filled",
            "jewish-star",
            "jpg",
            "json",
            "jump-rope",
            "karate",
            "kayak",
            "kering",
            "key-off",
            "key",
            "keyboard-hide",
            "keyboard-off",
            "keyboard-show",
            "keyboard",
            "keyframe-align-center",
            "keyframe-align-horizontal",
            "keyframe-align-vertical",
            "keyframe",
            "keyframes",
            "ladder-off",
            "ladder",
            "lambda",
            "lamp-2",
            "lamp-off",
            "lamp",
            "language-hiragana",
            "language-katakana",
            "language-off",
            "language",
            "lasso-off",
            "lasso-polygon",
            "lasso",
            "layers-difference",
            "layers-intersect-2",
            "layers-intersect",
            "layers-linked",
            "layers-off",
            "layers-subtract",
            "layers-union",
            "layout-2",
            "layout-align-bottom",
            "layout-align-center",
            "layout-align-left",
            "layout-align-middle",
            "layout-align-right",
            "layout-align-top",
            "layout-board-split",
            "layout-board",
            "layout-bottombar-collapse",
            "layout-bottombar-expand",
            "layout-bottombar",
            "layout-cards",
            "layout-collage",
            "layout-columns",
            "layout-dashboard",
            "layout-distribute-horizontal",
            "layout-distribute-vertical",
            "layout-grid-add",
            "layout-grid",
            "layout-kanban",
            "layout-list",
            "layout-navbar-collapse",
            "layout-navbar-expand",
            "layout-navbar",
            "layout-off",
            "layout-rows",
            "layout-sidebar-left-collapse",
            "layout-sidebar-left-expand",
            "layout-sidebar-right-collapse",
            "layout-sidebar-right-expand",
            "layout-sidebar-right",
            "layout-sidebar",
            "layout",
            "leaf-off",
            "leaf",
            "lego-off",
            "lego",
            "lemon-2",
            "lemon",
            "letter-a",
            "letter-b",
            "letter-c",
            "letter-case-lower",
            "letter-case-toggle",
            "letter-case-upper",
            "letter-case",
            "letter-d",
            "letter-e",
            "letter-f",
            "letter-g",
            "letter-h",
            "letter-i",
            "letter-j",
            "letter-k",
            "letter-l",
            "letter-m",
            "letter-n",
            "letter-o",
            "letter-p",
            "letter-q",
            "letter-r",
            "letter-s",
            "letter-spacing",
            "letter-t",
            "letter-u",
            "letter-v",
            "letter-w",
            "letter-x",
            "letter-y",
            "letter-z",
            "license-off",
            "license",
            "lifebuoy-off",
            "lifebuoy",
            "lighter",
            "line-dashed",
            "line-dotted",
            "line-height",
            "line",
            "link-off",
            "link",
            "list-check",
            "list-details",
            "list-numbers",
            "list-search",
            "list",
            "live-photo-off",
            "live-photo",
            "live-view",
            "loader-2",
            "loader-3",
            "loader-quarter",
            "loader",
            "location-broken",
            "location-filled",
            "location-off",
            "location",
            "lock-access-off",
            "lock-access",
            "lock-bolt",
            "lock-cancel",
            "lock-check",
            "lock-code",
            "lock-cog",
            "lock-dollar",
            "lock-down",
            "lock-exclamation",
            "lock-heart",
            "lock-minus",
            "lock-off",
            "lock-open-off",
            "lock-open",
            "lock-pause",
            "lock-pin",
            "lock-plus",
            "lock-question",
            "lock-search",
            "lock-share",
            "lock-square-rounded-filled",
            "lock-square-rounded",
            "lock-square",
            "lock-star",
            "lock-up",
            "lock-x",
            "lock",
            "logic-and",
            "logic-buffer",
            "logic-nand",
            "logic-nor",
            "logic-not",
            "logic-or",
            "logic-xnor",
            "logic-xor",
            "login",
            "logout",
            "lollipop-off",
            "lollipop",
            "luggage-off",
            "luggage",
            "lungs-off",
            "lungs",
            "macro-off",
            "macro",
            "magnet-off",
            "magnet",
            "mail-ai",
            "mail-bolt",
            "mail-cancel",
            "mail-check",
            "mail-code",
            "mail-cog",
            "mail-dollar",
            "mail-down",
            "mail-exclamation",
            "mail-fast",
            "mail-filled",
            "mail-forward",
            "mail-heart",
            "mail-minus",
            "mail-off",
            "mail-opened-filled",
            "mail-opened",
            "mail-pause",
            "mail-pin",
            "mail-plus",
            "mail-question",
            "mail-search",
            "mail-share",
            "mail-star",
            "mail-up",
            "mail-x",
            "mail",
            "mailbox-off",
            "mailbox",
            "man",
            "manual-gearbox",
            "map-2",
            "map-off",
            "map-pin-bolt",
            "map-pin-cancel",
            "map-pin-check",
            "map-pin-code",
            "map-pin-cog",
            "map-pin-dollar",
            "map-pin-down",
            "map-pin-exclamation",
            "map-pin-filled",
            "map-pin-heart",
            "map-pin-minus",
            "map-pin-off",
            "map-pin-pause",
            "map-pin-pin",
            "map-pin-plus",
            "map-pin-question",
            "map-pin-search",
            "map-pin-share",
            "map-pin-star",
            "map-pin-up",
            "map-pin-x",
            "map-pin",
            "map-pins",
            "map-search",
            "map",
            "markdown-off",
            "markdown",
            "marquee-2",
            "marquee-off",
            "marquee",
            "mars",
            "mask-off",
            "mask",
            "masks-theater-off",
            "masks-theater",
            "massage",
            "matchstick",
            "math-1-divide-2",
            "math-1-divide-3",
            "math-avg",
            "math-equal-greater",
            "math-equal-lower",
            "math-function-off",
            "math-function-y",
            "math-function",
            "math-greater",
            "math-integral-x",
            "math-integral",
            "math-integrals",
            "math-lower",
            "math-max",
            "math-min",
            "math-not",
            "math-off",
            "math-pi-divide-2",
            "math-pi",
            "math-symbols",
            "math-x-divide-2",
            "math-x-divide-y-2",
            "math-x-divide-y",
            "math-x-minus-x",
            "math-x-minus-y",
            "math-x-plus-x",
            "math-x-plus-y",
            "math-xy",
            "math-y-minus-y",
            "math-y-plus-y",
            "math",
            "maximize-off",
            "maximize",
            "meat-off",
            "meat",
            "medal-2",
            "medal",
            "medical-cross-filled",
            "medical-cross-off",
            "medical-cross",
            "medicine-syrup",
            "meeple",
            "menorah",
            "menu-2",
            "menu-order",
            "menu",
            "message-2-bolt",
            "message-2-cancel",
            "message-2-check",
            "message-2-code",
            "message-2-cog",
            "message-2-dollar",
            "message-2-down",
            "message-2-exclamation",
            "message-2-heart",
            "message-2-minus",
            "message-2-off",
            "message-2-pause",
            "message-2-pin",
            "message-2-plus",
            "message-2-question",
            "message-2-search",
            "message-2-share",
            "message-2-star",
            "message-2-up",
            "message-2-x",
            "message-2",
            "message-bolt",
            "message-cancel",
            "message-chatbot",
            "message-check",
            "message-circle-2-filled",
            "message-circle-2",
            "message-circle-bolt",
            "message-circle-cancel",
            "message-circle-check",
            "message-circle-code",
            "message-circle-cog",
            "message-circle-dollar",
            "message-circle-down",
            "message-circle-exclamation",
            "message-circle-heart",
            "message-circle-minus",
            "message-circle-off",
            "message-circle-pause",
            "message-circle-pin",
            "message-circle-plus",
            "message-circle-question",
            "message-circle-search",
            "message-circle-share",
            "message-circle-star",
            "message-circle-up",
            "message-circle-x",
            "message-circle",
            "message-code",
            "message-cog",
            "message-dollar",
            "message-dots",
            "message-down",
            "message-exclamation",
            "message-forward",
            "message-heart",
            "message-language",
            "message-minus",
            "message-off",
            "message-pause",
            "message-pin",
            "message-plus",
            "message-question",
            "message-report",
            "message-search",
            "message-share",
            "message-star",
            "message-up",
            "message-x",
            "message",
            "messages-off",
            "messages",
            "meteor-off",
            "meteor",
            "mickey-filled",
            "mickey",
            "microphone-2-off",
            "microphone-2",
            "microphone-off",
            "microphone",
            "microscope-off",
            "microscope",
            "microwave-off",
            "microwave",
            "military-award",
            "military-rank",
            "milk-off",
            "milk",
            "milkshake",
            "minimize",
            "minus-vertical",
            "minus",
            "mist-off",
            "mist",
            "mobiledata-off",
            "mobiledata",
            "moneybag",
            "mood-angry",
            "mood-annoyed-2",
            "mood-annoyed",
            "mood-boy",
            "mood-check",
            "mood-cog",
            "mood-confuzed-filled",
            "mood-confuzed",
            "mood-crazy-happy",
            "mood-cry",
            "mood-dollar",
            "mood-edit",
            "mood-empty-filled",
            "mood-empty",
            "mood-happy-filled",
            "mood-happy",
            "mood-heart",
            "mood-kid-filled",
            "mood-kid",
            "mood-look-left",
            "mood-look-right",
            "mood-minus",
            "mood-nerd",
            "mood-nervous",
            "mood-neutral-filled",
            "mood-neutral",
            "mood-off",
            "mood-pin",
            "mood-plus",
            "mood-sad-2",
            "mood-sad-dizzy",
            "mood-sad-filled",
            "mood-sad-squint",
            "mood-sad",
            "mood-search",
            "mood-share",
            "mood-sick",
            "mood-silence",
            "mood-sing",
            "mood-smile-beam",
            "mood-smile-dizzy",
            "mood-smile-filled",
            "mood-smile",
            "mood-suprised",
            "mood-tongue-wink-2",
            "mood-tongue-wink",
            "mood-tongue",
            "mood-unamused",
            "mood-up",
            "mood-wink-2",
            "mood-wink",
            "mood-wrrr",
            "mood-x",
            "mood-xd",
            "moon-2",
            "moon-filled",
            "moon-off",
            "moon-stars",
            "moon",
            "moped",
            "motorbike",
            "mountain-off",
            "mountain",
            "mouse-2",
            "mouse-off",
            "mouse",
            "moustache",
            "movie-off",
            "movie",
            "mug-off",
            "mug",
            "multiplier-0-5x",
            "multiplier-1-5x",
            "multiplier-1x",
            "multiplier-2x",
            "mushroom-filled",
            "mushroom-off",
            "mushroom",
            "music-off",
            "music",
            "navigation-filled",
            "navigation-off",
            "navigation",
            "needle-thread",
            "needle",
            "network-off",
            "network",
            "new-section",
            "news-off",
            "news",
            "nfc-off",
            "nfc",
            "no-copyright",
            "no-creative-commons",
            "no-derivatives",
            "north-star",
            "note-off",
            "note",
            "notebook-off",
            "notebook",
            "notes-off",
            "notes",
            "notification-off",
            "notification",
            "number-0",
            "number-1",
            "number-2",
            "number-3",
            "number-4",
            "number-5",
            "number-6",
            "number-7",
            "number-8",
            "number-9",
            "number",
            "numbers",
            "nurse",
            "octagon-filled",
            "octagon-off",
            "octagon",
            "old",
            "olympics-off",
            "olympics",
            "om",
            "omega",
            "outbound",
            "outlet",
            "oval-filled",
            "oval-vertical-filled",
            "oval-vertical",
            "oval",
            "overline",
            "package-export",
            "package-import",
            "package-off",
            "package",
            "packages",
            "pacman",
            "page-break",
            "paint-filled",
            "paint-off",
            "paint",
            "palette-off",
            "palette",
            "panorama-horizontal-off",
            "panorama-horizontal",
            "panorama-vertical-off",
            "panorama-vertical",
            "paper-bag-off",
            "paper-bag",
            "paperclip",
            "parachute-off",
            "parachute",
            "parentheses-off",
            "parentheses",
            "parking-off",
            "parking",
            "password",
            "paw-filled",
            "paw-off",
            "paw",
            "pdf",
            "peace",
            "pencil-minus",
            "pencil-off",
            "pencil-plus",
            "pencil",
            "pennant-2-filled",
            "pennant-2",
            "pennant-filled",
            "pennant-off",
            "pennant",
            "pentagon-filled",
            "pentagon-off",
            "pentagon",
            "pentagram",
            "pepper-off",
            "pepper",
            "percentage",
            "perfume",
            "perspective-off",
            "perspective",
            "phone-call",
            "phone-calling",
            "phone-check",
            "phone-filled",
            "phone-incoming",
            "phone-off",
            "phone-outgoing",
            "phone-pause",
            "phone-plus",
            "phone-x",
            "phone",
            "photo-ai",
            "photo-bolt",
            "photo-cancel",
            "photo-check",
            "photo-code",
            "photo-cog",
            "photo-dollar",
            "photo-down",
            "photo-edit",
            "photo-exclamation",
            "photo-filled",
            "photo-heart",
            "photo-minus",
            "photo-off",
            "photo-pause",
            "photo-pin",
            "photo-plus",
            "photo-question",
            "photo-search",
            "photo-sensor-2",
            "photo-sensor-3",
            "photo-sensor",
            "photo-share",
            "photo-shield",
            "photo-star",
            "photo-up",
            "photo-x",
            "photo",
            "physotherapist",
            "picture-in-picture-off",
            "picture-in-picture-on",
            "picture-in-picture-top",
            "picture-in-picture",
            "pig-money",
            "pig-off",
            "pig",
            "pilcrow",
            "pill-off",
            "pill",
            "pills",
            "pin-filled",
            "pin",
            "ping-pong",
            "pinned-filled",
            "pinned-off",
            "pinned",
            "pizza-off",
            "pizza",
            "placeholder",
            "plane-arrival",
            "plane-departure",
            "plane-inflight",
            "plane-off",
            "plane-tilt",
            "plane",
            "planet-off",
            "planet",
            "plant-2-off",
            "plant-2",
            "plant-off",
            "plant",
            "play-card-off",
            "play-card",
            "player-eject-filled",
            "player-eject",
            "player-pause-filled",
            "player-pause",
            "player-play-filled",
            "player-play",
            "player-record-filled",
            "player-record",
            "player-skip-back-filled",
            "player-skip-back",
            "player-skip-forward-filled",
            "player-skip-forward",
            "player-stop-filled",
            "player-stop",
            "player-track-next-filled",
            "player-track-next",
            "player-track-prev-filled",
            "player-track-prev",
            "playlist-add",
            "playlist-off",
            "playlist-x",
            "playlist",
            "playstation-circle",
            "playstation-square",
            "playstation-triangle",
            "playstation-x",
            "plug-connected-x",
            "plug-connected",
            "plug-off",
            "plug-x",
            "plug",
            "plus-equal",
            "plus-minus",
            "plus",
            "png",
            "podium-off",
            "podium",
            "point-filled",
            "point-off",
            "point",
            "pointer-bolt",
            "pointer-cancel",
            "pointer-check",
            "pointer-code",
            "pointer-cog",
            "pointer-dollar",
            "pointer-down",
            "pointer-exclamation",
            "pointer-heart",
            "pointer-minus",
            "pointer-off",
            "pointer-pause",
            "pointer-pin",
            "pointer-plus",
            "pointer-question",
            "pointer-search",
            "pointer-share",
            "pointer-star",
            "pointer-up",
            "pointer-x",
            "pointer",
            "pokeball-off",
            "pokeball",
            "poker-chip",
            "polaroid-filled",
            "polaroid",
            "polygon-off",
            "polygon",
            "poo",
            "pool-off",
            "pool",
            "power",
            "pray",
            "premium-rights",
            "prescription",
            "presentation-analytics",
            "presentation-off",
            "presentation",
            "printer-off",
            "printer",
            "prison",
            "progress-alert",
            "progress-bolt",
            "progress-check",
            "progress-down",
            "progress-help",
            "progress-x",
            "progress",
            "prompt",
            "propeller-off",
            "propeller",
            "pumpkin-scary",
            "puzzle-2",
            "puzzle-filled",
            "puzzle-off",
            "puzzle",
            "pyramid-off",
            "pyramid",
            "qrcode-off",
            "qrcode",
            "question-mark",
            "quote-off",
            "quote",
            "radar-2",
            "radar-off",
            "radar",
            "radio-off",
            "radio",
            "radioactive-filled",
            "radioactive-off",
            "radioactive",
            "radius-bottom-left",
            "radius-bottom-right",
            "radius-top-left",
            "radius-top-right",
            "rainbow-off",
            "rainbow",
            "rating-12-plus",
            "rating-14-plus",
            "rating-16-plus",
            "rating-18-plus",
            "rating-21-plus",
            "razor-electric",
            "razor",
            "receipt-2",
            "receipt-off",
            "receipt-refund",
            "receipt-tax",
            "receipt",
            "recharging",
            "record-mail-off",
            "record-mail",
            "rectangle-filled",
            "rectangle-vertical-filled",
            "rectangle-vertical",
            "rectangle",
            "recycle-off",
            "recycle",
            "refresh-alert",
            "refresh-dot",
            "refresh-off",
            "refresh",
            "regex-off",
            "regex",
            "registered",
            "relation-many-to-many",
            "relation-one-to-many",
            "relation-one-to-one",
            "reload",
            "repeat-off",
            "repeat-once",
            "repeat",
            "replace-filled",
            "replace-off",
            "replace",
            "report-analytics",
            "report-medical",
            "report-money",
            "report-off",
            "report-search",
            "report",
            "reserved-line",
            "resize",
            "ribbon-health",
            "ripple-off",
            "ripple",
            "road-off",
            "road-sign",
            "road",
            "robot-off",
            "robot",
            "rocket-off",
            "rocket",
            "roller-skating",
            "rollercoaster-off",
            "rollercoaster",
            "rosette-filled",
            "rosette-number-0",
            "rosette-number-1",
            "rosette-number-2",
            "rosette-number-3",
            "rosette-number-4",
            "rosette-number-5",
            "rosette-number-6",
            "rosette-number-7",
            "rosette-number-8",
            "rosette-number-9",
            "rosette",
            "rotate-2",
            "rotate-360",
            "rotate-clockwise-2",
            "rotate-clockwise",
            "rotate-dot",
            "rotate-rectangle",
            "rotate",
            "route-2",
            "route-off",
            "route",
            "router-off",
            "router",
            "row-insert-bottom",
            "row-insert-top",
            "rss",
            "rubber-stamp-off",
            "rubber-stamp",
            "ruler-2-off",
            "ruler-2",
            "ruler-3",
            "ruler-measure",
            "ruler-off",
            "ruler",
            "run",
            "s-turn-down",
            "s-turn-left",
            "s-turn-right",
            "s-turn-up",
            "sailboat-2",
            "sailboat-off",
            "sailboat",
            "salad",
            "salt",
            "satellite-off",
            "satellite",
            "sausage",
            "scale-off",
            "scale-outline-off",
            "scale-outline",
            "scale",
            "scan-eye",
            "scan",
            "schema-off",
            "schema",
            "school-bell",
            "school-off",
            "school",
            "scissors-off",
            "scissors",
            "scooter-electric",
            "scooter",
            "screen-share-off",
            "screen-share",
            "screenshot",
            "scribble-off",
            "scribble",
            "script-minus",
            "script-plus",
            "script-x",
            "script",
            "scuba-mask-off",
            "scuba-mask",
            "sdk",
            "search-off",
            "search",
            "section-sign",
            "section",
            "seeding-off",
            "seeding",
            "select-all",
            "select",
            "selector",
            "send-off",
            "send",
            "seo",
            "separator-horizontal",
            "separator-vertical",
            "separator",
            "server-2",
            "server-bolt",
            "server-cog",
            "server-off",
            "server",
            "servicemark",
            "settings-2",
            "settings-automation",
            "settings-bolt",
            "settings-cancel",
            "settings-check",
            "settings-code",
            "settings-cog",
            "settings-dollar",
            "settings-down",
            "settings-exclamation",
            "settings-filled",
            "settings-heart",
            "settings-minus",
            "settings-off",
            "settings-pause",
            "settings-pin",
            "settings-plus",
            "settings-question",
            "settings-search",
            "settings-share",
            "settings-star",
            "settings-up",
            "settings-x",
            "settings",
            "shadow-off",
            "shadow",
            "shape-2",
            "shape-3",
            "shape-off",
            "shape",
            "share-2",
            "share-3",
            "share-off",
            "share",
            "shield-bolt",
            "shield-cancel",
            "shield-check-filled",
            "shield-check",
            "shield-checkered-filled",
            "shield-checkered",
            "shield-chevron",
            "shield-code",
            "shield-cog",
            "shield-dollar",
            "shield-down",
            "shield-exclamation",
            "shield-filled",
            "shield-half-filled",
            "shield-half",
            "shield-heart",
            "shield-lock-filled",
            "shield-lock",
            "shield-minus",
            "shield-off",
            "shield-pause",
            "shield-pin",
            "shield-plus",
            "shield-question",
            "shield-search",
            "shield-share",
            "shield-star",
            "shield-up",
            "shield-x",
            "shield",
            "ship-off",
            "ship",
            "shirt-filled",
            "shirt-off",
            "shirt-sport",
            "shirt",
            "shoe-off",
            "shoe",
            "shopping-bag",
            "shopping-cart-discount",
            "shopping-cart-off",
            "shopping-cart-plus",
            "shopping-cart-x",
            "shopping-cart",
            "shovel",
            "shredder",
            "sign-left-filled",
            "sign-left",
            "sign-right-filled",
            "sign-right",
            "signal-2g",
            "signal-3g",
            "signal-4g-plus",
            "signal-4g",
            "signal-5g",
            "signal-6g",
            "signal-e",
            "signal-g",
            "signal-h-plus",
            "signal-h",
            "signal-lte",
            "signature-off",
            "signature",
            "sitemap-off",
            "sitemap",
            "skateboard-off",
            "skateboard",
            "skull",
            "slash",
            "slashes",
            "sleigh",
            "slice",
            "slideshow",
            "smart-home-off",
            "smart-home",
            "smoking-no",
            "smoking",
            "snowflake-off",
            "snowflake",
            "snowman",
            "soccer-field",
            "social-off",
            "social",
            "sock",
            "sofa-off",
            "sofa",
            "solar-panel-2",
            "solar-panel",
            "sort-0-9",
            "sort-9-0",
            "sort-a-z",
            "sort-ascending-2",
            "sort-ascending-letters",
            "sort-ascending-numbers",
            "sort-ascending",
            "sort-descending-2",
            "sort-descending-letters",
            "sort-descending-numbers",
            "sort-descending",
            "sort-z-a",
            "sos",
            "soup-off",
            "soup",
            "source-code",
            "space-off",
            "space",
            "spacing-horizontal",
            "spacing-vertical",
            "spade-filled",
            "spade",
            "sparkles",
            "speakerphone",
            "speedboat",
            "spider",
            "spiral-off",
            "spiral",
            "sport-billard",
            "spray",
            "spy-off",
            "spy",
            "sql",
            "square-0-filled",
            "square-1-filled",
            "square-2-filled",
            "square-3-filled",
            "square-4-filled",
            "square-5-filled",
            "square-6-filled",
            "square-7-filled",
            "square-8-filled",
            "square-9-filled",
            "square-arrow-down",
            "square-arrow-left",
            "square-arrow-right",
            "square-arrow-up",
            "square-asterisk",
            "square-check-filled",
            "square-check",
            "square-chevron-down",
            "square-chevron-left",
            "square-chevron-right",
            "square-chevron-up",
            "square-chevrons-down",
            "square-chevrons-left",
            "square-chevrons-right",
            "square-chevrons-up",
            "square-dot",
            "square-f0-filled",
            "square-f0",
            "square-f1-filled",
            "square-f1",
            "square-f2-filled",
            "square-f2",
            "square-f3-filled",
            "square-f3",
            "square-f4-filled",
            "square-f4",
            "square-f5-filled",
            "square-f5",
            "square-f6-filled",
            "square-f6",
            "square-f7-filled",
            "square-f7",
            "square-f8-filled",
            "square-f8",
            "square-f9-filled",
            "square-f9",
            "square-forbid-2",
            "square-forbid",
            "square-half",
            "square-key",
            "square-letter-a",
            "square-letter-b",
            "square-letter-c",
            "square-letter-d",
            "square-letter-e",
            "square-letter-f",
            "square-letter-g",
            "square-letter-h",
            "square-letter-i",
            "square-letter-j",
            "square-letter-k",
            "square-letter-l",
            "square-letter-m",
            "square-letter-n",
            "square-letter-o",
            "square-letter-p",
            "square-letter-q",
            "square-letter-r",
            "square-letter-s",
            "square-letter-t",
            "square-letter-u",
            "square-letter-v",
            "square-letter-w",
            "square-letter-x",
            "square-letter-y",
            "square-letter-z",
            "square-minus",
            "square-number-0",
            "square-number-1",
            "square-number-2",
            "square-number-3",
            "square-number-4",
            "square-number-5",
            "square-number-6",
            "square-number-7",
            "square-number-8",
            "square-number-9",
            "square-off",
            "square-plus",
            "square-root-2",
            "square-root",
            "square-rotated-filled",
            "square-rotated-forbid-2",
            "square-rotated-forbid",
            "square-rotated-off",
            "square-rotated",
            "square-rounded-arrow-down-filled",
            "square-rounded-arrow-down",
            "square-rounded-arrow-left-filled",
            "square-rounded-arrow-left",
            "square-rounded-arrow-right-filled",
            "square-rounded-arrow-right",
            "square-rounded-arrow-up-filled",
            "square-rounded-arrow-up",
            "square-rounded-check-filled",
            "square-rounded-check",
            "square-rounded-chevron-down-filled",
            "square-rounded-chevron-down",
            "square-rounded-chevron-left-filled",
            "square-rounded-chevron-left",
            "square-rounded-chevron-right-filled",
            "square-rounded-chevron-right",
            "square-rounded-chevron-up-filled",
            "square-rounded-chevron-up",
            "square-rounded-chevrons-down-filled",
            "square-rounded-chevrons-down",
            "square-rounded-chevrons-left-filled",
            "square-rounded-chevrons-left",
            "square-rounded-chevrons-right-filled",
            "square-rounded-chevrons-right",
            "square-rounded-chevrons-up-filled",
            "square-rounded-chevrons-up",
            "square-rounded-filled",
            "square-rounded-letter-a",
            "square-rounded-letter-b",
            "square-rounded-letter-c",
            "square-rounded-letter-d",
            "square-rounded-letter-e",
            "square-rounded-letter-f",
            "square-rounded-letter-g",
            "square-rounded-letter-h",
            "square-rounded-letter-i",
            "square-rounded-letter-j",
            "square-rounded-letter-k",
            "square-rounded-letter-l",
            "square-rounded-letter-m",
            "square-rounded-letter-n",
            "square-rounded-letter-o",
            "square-rounded-letter-p",
            "square-rounded-letter-q",
            "square-rounded-letter-r",
            "square-rounded-letter-s",
            "square-rounded-letter-t",
            "square-rounded-letter-u",
            "square-rounded-letter-v",
            "square-rounded-letter-w",
            "square-rounded-letter-x",
            "square-rounded-letter-y",
            "square-rounded-letter-z",
            "square-rounded-minus",
            "square-rounded-number-0-filled",
            "square-rounded-number-0",
            "square-rounded-number-1-filled",
            "square-rounded-number-1",
            "square-rounded-number-2-filled",
            "square-rounded-number-2",
            "square-rounded-number-3-filled",
            "square-rounded-number-3",
            "square-rounded-number-4-filled",
            "square-rounded-number-4",
            "square-rounded-number-5-filled",
            "square-rounded-number-5",
            "square-rounded-number-6-filled",
            "square-rounded-number-6",
            "square-rounded-number-7-filled",
            "square-rounded-number-7",
            "square-rounded-number-8-filled",
            "square-rounded-number-8",
            "square-rounded-number-9-filled",
            "square-rounded-number-9",
            "square-rounded-plus-filled",
            "square-rounded-plus",
            "square-rounded-x-filled",
            "square-rounded-x",
            "square-rounded",
            "square-toggle-horizontal",
            "square-toggle",
            "square-x",
            "square",
            "squares-diagonal",
            "squares-filled",
            "stack-2",
            "stack-3",
            "stack-pop",
            "stack-push",
            "stack",
            "stairs-down",
            "stairs-up",
            "stairs",
            "star-filled",
            "star-half-filled",
            "star-half",
            "star-off",
            "star",
            "stars-filled",
            "stars-off",
            "stars",
            "status-change",
            "steam",
            "steering-wheel-off",
            "steering-wheel",
            "step-into",
            "step-out",
            "stereo-glasses",
            "stethoscope-off",
            "stethoscope",
            "sticker",
            "storm-off",
            "storm",
            "stretching",
            "strikethrough",
            "submarine",
            "subscript",
            "subtask",
            "sum-off",
            "sum",
            "sun-filled",
            "sun-high",
            "sun-low",
            "sun-moon",
            "sun-off",
            "sun-wind",
            "sun",
            "sunglasses",
            "sunrise",
            "sunset-2",
            "sunset",
            "superscript",
            "svg",
            "swimming",
            "swipe",
            "switch-2",
            "switch-3",
            "switch-horizontal",
            "switch-vertical",
            "switch",
            "sword-off",
            "sword",
            "swords",
            "table-alias",
            "table-down",
            "table-export",
            "table-filled",
            "table-heart",
            "table-import",
            "table-minus",
            "table-off",
            "table-options",
            "table-plus",
            "table-share",
            "table-shortcut",
            "table",
            "tag-off",
            "tag",
            "tags-off",
            "tags",
            "tallymark-1",
            "tallymark-2",
            "tallymark-3",
            "tallymark-4",
            "tallymarks",
            "tank",
            "target-arrow",
            "target-off",
            "target",
            "teapot",
            "telescope-off",
            "telescope",
            "temperature-celsius",
            "temperature-fahrenheit",
            "temperature-minus",
            "temperature-off",
            "temperature-plus",
            "temperature",
            "template-off",
            "template",
            "tent-off",
            "tent",
            "terminal-2",
            "terminal",
            "test-pipe-2",
            "test-pipe-off",
            "test-pipe",
            "tex",
            "text-caption",
            "text-color",
            "text-decrease",
            "text-direction-ltr",
            "text-direction-rtl",
            "text-increase",
            "text-orientation",
            "text-plus",
            "text-recognition",
            "text-resize",
            "text-size",
            "text-spellcheck",
            "text-wrap-disabled",
            "text-wrap",
            "texture",
            "theater",
            "thermometer",
            "thumb-down-filled",
            "thumb-down-off",
            "thumb-down",
            "thumb-up-filled",
            "thumb-up-off",
            "thumb-up",
            "tic-tac",
            "ticket-off",
            "ticket",
            "tie",
            "tilde",
            "tilt-shift-off",
            "tilt-shift",
            "timeline-event-exclamation",
            "timeline-event-minus",
            "timeline-event-plus",
            "timeline-event-text",
            "timeline-event-x",
            "timeline-event",
            "timeline",
            "tir",
            "toggle-left",
            "toggle-right",
            "toilet-paper-off",
            "toilet-paper",
            "tool",
            "tools-kitchen-2-off",
            "tools-kitchen-2",
            "tools-kitchen-off",
            "tools-kitchen",
            "tools-off",
            "tools",
            "tooltip",
            "topology-bus",
            "topology-complex",
            "topology-full-hierarchy",
            "topology-full",
            "topology-ring-2",
            "topology-ring-3",
            "topology-ring",
            "topology-star-2",
            "topology-star-3",
            "topology-star-ring-2",
            "topology-star-ring-3",
            "topology-star-ring",
            "topology-star",
            "torii",
            "tornado",
            "tournament",
            "tower-off",
            "tower",
            "track",
            "tractor",
            "trademark",
            "traffic-cone-off",
            "traffic-cone",
            "traffic-lights-off",
            "traffic-lights",
            "train",
            "transfer-in",
            "transfer-out",
            "transform-filled",
            "transform",
            "transition-bottom",
            "transition-left",
            "transition-right",
            "transition-top",
            "trash-filled",
            "trash-off",
            "trash-x-filled",
            "trash-x",
            "trash",
            "tree",
            "trees",
            "trekking",
            "trending-down-2",
            "trending-down-3",
            "trending-down",
            "trending-up-2",
            "trending-up-3",
            "trending-up",
            "triangle-filled",
            "triangle-inverted-filled",
            "triangle-inverted",
            "triangle-off",
            "triangle-square-circle",
            "triangle",
            "triangles",
            "trident",
            "trolley",
            "trophy-filled",
            "trophy-off",
            "trophy",
            "trowel",
            "truck-delivery",
            "truck-loading",
            "truck-off",
            "truck-return",
            "truck",
            "txt",
            "typography-off",
            "typography",
            "ufo-off",
            "ufo",
            "umbrella-filled",
            "umbrella-off",
            "umbrella",
            "underline",
            "unlink",
            "upload",
            "urgent",
            "usb",
            "user-bolt",
            "user-cancel",
            "user-check",
            "user-circle",
            "user-code",
            "user-cog",
            "user-dollar",
            "user-down",
            "user-edit",
            "user-exclamation",
            "user-heart",
            "user-minus",
            "user-off",
            "user-pause",
            "user-pin",
            "user-plus",
            "user-question",
            "user-search",
            "user-share",
            "user-shield",
            "user-star",
            "user-up",
            "user-x",
            "user",
            "users-group",
            "users-minus",
            "users-plus",
            "users",
            "uv-index",
            "ux-circle",
            "vaccine-bottle-off",
            "vaccine-bottle",
            "vaccine-off",
            "vaccine",
            "vacuum-cleaner",
            "variable-minus",
            "variable-off",
            "variable-plus",
            "variable",
            "vector-bezier-2",
            "vector-bezier-arc",
            "vector-bezier-circle",
            "vector-bezier",
            "vector-off",
            "vector-spline",
            "vector-triangle-off",
            "vector-triangle",
            "vector",
            "venus",
            "versions-filled",
            "versions-off",
            "versions",
            "video-minus",
            "video-off",
            "video-plus",
            "video",
            "view-360-off",
            "view-360",
            "viewfinder-off",
            "viewfinder",
            "viewport-narrow",
            "viewport-wide",
            "vinyl",
            "vip-off",
            "vip",
            "virus-off",
            "virus-search",
            "virus",
            "vocabulary-off",
            "vocabulary",
            "volcano",
            "volume-2",
            "volume-3",
            "volume-off",
            "volume",
            "walk",
            "wall-off",
            "wall",
            "wallet-off",
            "wallet",
            "wallpaper-off",
            "wallpaper",
            "wand-off",
            "wand",
            "wash-dry-1",
            "wash-dry-2",
            "wash-dry-3",
            "wash-dry-a",
            "wash-dry-dip",
            "wash-dry-f",
            "wash-dry-hang",
            "wash-dry-off",
            "wash-dry-p",
            "wash-dry-shade",
            "wash-dry-w",
            "wash-dry",
            "wash-dryclean-off",
            "wash-dryclean",
            "wash-gentle",
            "wash-machine",
            "wash-off",
            "wash-press",
            "wash-temperature-1",
            "wash-temperature-2",
            "wash-temperature-3",
            "wash-temperature-4",
            "wash-temperature-5",
            "wash-temperature-6",
            "wash-tumble-dry",
            "wash-tumble-off",
            "wash",
            "wave-saw-tool",
            "wave-sine",
            "wave-square",
            "webhook-off",
            "webhook",
            "weight",
            "wheelchair-off",
            "wheelchair",
            "whirl",
            "wifi-0",
            "wifi-1",
            "wifi-2",
            "wifi-off",
            "wifi",
            "wind-off",
            "wind",
            "windmill-filled",
            "windmill-off",
            "windmill",
            "window-maximize",
            "window-minimize",
            "window-off",
            "window",
            "windsock",
            "wiper-wash",
            "wiper",
            "woman",
            "wood",
            "world-bolt",
            "world-cancel",
            "world-check",
            "world-code",
            "world-cog",
            "world-dollar",
            "world-down",
            "world-download",
            "world-exclamation",
            "world-heart",
            "world-latitude",
            "world-longitude",
            "world-minus",
            "world-off",
            "world-pause",
            "world-pin",
            "world-plus",
            "world-question",
            "world-search",
            "world-share",
            "world-star",
            "world-up",
            "world-upload",
            "world-www",
            "world-x",
            "world",
            "wrecking-ball",
            "writing-off",
            "writing-sign-off",
            "writing-sign",
            "writing",
            "x",
            "xbox-a",
            "xbox-b",
            "xbox-x",
            "xbox-y",
            "xd",
            "yin-yang-filled",
            "yin-yang",
            "yoga",
            "zeppelin-off",
            "zeppelin",
            "zip",
            "zodiac-aquarius",
            "zodiac-aries",
            "zodiac-cancer",
            "zodiac-capricorn",
            "zodiac-gemini",
            "zodiac-leo",
            "zodiac-libra",
            "zodiac-pisces",
            "zodiac-sagittarius",
            "zodiac-scorpio",
            "zodiac-taurus",
            "zodiac-virgo",
            "zoom-cancel",
            "zoom-check-filled",
            "zoom-check",
            "zoom-code",
            "zoom-exclamation",
            "zoom-filled",
            "zoom-in-area-filled",
            "zoom-in-area",
            "zoom-in-filled",
            "zoom-in",
            "zoom-money",
            "zoom-out-area",
            "zoom-out-filled",
            "zoom-out",
            "zoom-pan",
            "zoom-question",
            "zoom-replace",
            "zoom-reset",
            "zzz-off",
            "zzz",
        ],
        optionTitles: [
            "123",
            "24-hours",
            "2fa",
            "360-view",
            "360",
            "3d-cube-sphere-off",
            "3d-cube-sphere",
            "3d-rotate",
            "a-b-2",
            "a-b-off",
            "a-b",
            "abacus-off",
            "abacus",
            "abc",
            "access-point-off",
            "access-point",
            "accessible-off-filled",
            "accessible-off",
            "accessible",
            "activity-heartbeat",
            "activity",
            "ad-2",
            "ad-circle-filled",
            "ad-circle-off",
            "ad-circle",
            "ad-filled",
            "ad-off",
            "ad",
            "address-book-off",
            "address-book",
            "adjustments-alt",
            "adjustments-bolt",
            "adjustments-cancel",
            "adjustments-check",
            "adjustments-code",
            "adjustments-cog",
            "adjustments-dollar",
            "adjustments-down",
            "adjustments-exclamation",
            "adjustments-filled",
            "adjustments-heart",
            "adjustments-horizontal",
            "adjustments-minus",
            "adjustments-off",
            "adjustments-pause",
            "adjustments-pin",
            "adjustments-plus",
            "adjustments-question",
            "adjustments-search",
            "adjustments-share",
            "adjustments-star",
            "adjustments-up",
            "adjustments-x",
            "adjustments",
            "aerial-lift",
            "affiliate-filled",
            "affiliate",
            "air-balloon",
            "air-conditioning-disabled",
            "air-conditioning",
            "alarm-filled",
            "alarm-minus-filled",
            "alarm-minus",
            "alarm-off",
            "alarm-plus-filled",
            "alarm-plus",
            "alarm-snooze-filled",
            "alarm-snooze",
            "alarm",
            "album-off",
            "album",
            "alert-circle-filled",
            "alert-circle",
            "alert-hexagon-filled",
            "alert-hexagon",
            "alert-octagon-filled",
            "alert-octagon",
            "alert-small",
            "alert-square-filled",
            "alert-square-rounded-filled",
            "alert-square-rounded",
            "alert-square",
            "alert-triangle-filled",
            "alert-triangle",
            "alien-filled",
            "alien",
            "align-box-bottom-center-filled",
            "align-box-bottom-center",
            "align-box-bottom-left-filled",
            "align-box-bottom-left",
            "align-box-bottom-right-filled",
            "align-box-bottom-right",
            "align-box-center-middle-filled",
            "align-box-center-middle",
            "align-box-left-bottom-filled",
            "align-box-left-bottom",
            "align-box-left-middle-filled",
            "align-box-left-middle",
            "align-box-left-top-filled",
            "align-box-left-top",
            "align-box-right-bottom-filled",
            "align-box-right-bottom",
            "align-box-right-middle-filled",
            "align-box-right-middle",
            "align-box-right-top-filled",
            "align-box-right-top",
            "align-box-top-center-filled",
            "align-box-top-center",
            "align-box-top-left-filled",
            "align-box-top-left",
            "align-box-top-right-filled",
            "align-box-top-right",
            "align-center",
            "align-justified",
            "align-left",
            "align-right",
            "alpha",
            "alphabet-cyrillic",
            "alphabet-greek",
            "alphabet-latin",
            "ambulance",
            "ampersand",
            "analyze-filled",
            "analyze-off",
            "analyze",
            "anchor-off",
            "anchor",
            "angle",
            "ankh",
            "antenna-bars-1",
            "antenna-bars-2",
            "antenna-bars-3",
            "antenna-bars-4",
            "antenna-bars-5",
            "antenna-bars-off",
            "antenna-off",
            "antenna",
            "aperture-off",
            "aperture",
            "api-app-off",
            "api-app",
            "api-off",
            "api",
            "app-window-filled",
            "app-window",
            "apple",
            "apps-filled",
            "apps-off",
            "apps",
            "archive-off",
            "archive",
            "armchair-2-off",
            "armchair-2",
            "armchair-off",
            "armchair",
            "arrow-autofit-content-filled",
            "arrow-autofit-content",
            "arrow-autofit-down",
            "arrow-autofit-height",
            "arrow-autofit-left",
            "arrow-autofit-right",
            "arrow-autofit-up",
            "arrow-autofit-width",
            "arrow-back-up-double",
            "arrow-back-up",
            "arrow-back",
            "arrow-badge-down-filled",
            "arrow-badge-down",
            "arrow-badge-left-filled",
            "arrow-badge-left",
            "arrow-badge-right-filled",
            "arrow-badge-right",
            "arrow-badge-up-filled",
            "arrow-badge-up",
            "arrow-bar-down",
            "arrow-bar-left",
            "arrow-bar-right",
            "arrow-bar-to-down",
            "arrow-bar-to-left",
            "arrow-bar-to-right",
            "arrow-bar-to-up",
            "arrow-bar-up",
            "arrow-bear-left-2",
            "arrow-bear-left",
            "arrow-bear-right-2",
            "arrow-bear-right",
            "arrow-big-down-filled",
            "arrow-big-down-line-filled",
            "arrow-big-down-line",
            "arrow-big-down-lines-filled",
            "arrow-big-down-lines",
            "arrow-big-down",
            "arrow-big-left-filled",
            "arrow-big-left-line-filled",
            "arrow-big-left-line",
            "arrow-big-left-lines-filled",
            "arrow-big-left-lines",
            "arrow-big-left",
            "arrow-big-right-filled",
            "arrow-big-right-line-filled",
            "arrow-big-right-line",
            "arrow-big-right-lines-filled",
            "arrow-big-right-lines",
            "arrow-big-right",
            "arrow-big-up-filled",
            "arrow-big-up-line-filled",
            "arrow-big-up-line",
            "arrow-big-up-lines-filled",
            "arrow-big-up-lines",
            "arrow-big-up",
            "arrow-bounce",
            "arrow-curve-left",
            "arrow-curve-right",
            "arrow-down-bar",
            "arrow-down-circle",
            "arrow-down-left-circle",
            "arrow-down-left",
            "arrow-down-rhombus",
            "arrow-down-right-circle",
            "arrow-down-right",
            "arrow-down-square",
            "arrow-down-tail",
            "arrow-down",
            "arrow-elbow-left",
            "arrow-elbow-right",
            "arrow-fork",
            "arrow-forward-up-double",
            "arrow-forward-up",
            "arrow-forward",
            "arrow-guide",
            "arrow-iteration",
            "arrow-left-bar",
            "arrow-left-circle",
            "arrow-left-rhombus",
            "arrow-left-right",
            "arrow-left-square",
            "arrow-left-tail",
            "arrow-left",
            "arrow-loop-left-2",
            "arrow-loop-left",
            "arrow-loop-right-2",
            "arrow-loop-right",
            "arrow-merge-both",
            "arrow-merge-left",
            "arrow-merge-right",
            "arrow-merge",
            "arrow-move-down",
            "arrow-move-left",
            "arrow-move-right",
            "arrow-move-up",
            "arrow-narrow-down",
            "arrow-narrow-left",
            "arrow-narrow-right",
            "arrow-narrow-up",
            "arrow-ramp-left-2",
            "arrow-ramp-left-3",
            "arrow-ramp-left",
            "arrow-ramp-right-2",
            "arrow-ramp-right-3",
            "arrow-ramp-right",
            "arrow-right-bar",
            "arrow-right-circle",
            "arrow-right-rhombus",
            "arrow-right-square",
            "arrow-right-tail",
            "arrow-right",
            "arrow-rotary-first-left",
            "arrow-rotary-first-right",
            "arrow-rotary-last-left",
            "arrow-rotary-last-right",
            "arrow-rotary-left",
            "arrow-rotary-right",
            "arrow-rotary-straight",
            "arrow-roundabout-left",
            "arrow-roundabout-right",
            "arrow-sharp-turn-left",
            "arrow-sharp-turn-right",
            "arrow-up-bar",
            "arrow-up-circle",
            "arrow-up-left-circle",
            "arrow-up-left",
            "arrow-up-rhombus",
            "arrow-up-right-circle",
            "arrow-up-right",
            "arrow-up-square",
            "arrow-up-tail",
            "arrow-up",
            "arrow-wave-left-down",
            "arrow-wave-left-up",
            "arrow-wave-right-down",
            "arrow-wave-right-up",
            "arrow-zig-zag",
            "arrows-cross",
            "arrows-diagonal-2",
            "arrows-diagonal-minimize-2",
            "arrows-diagonal-minimize",
            "arrows-diagonal",
            "arrows-diff",
            "arrows-double-ne-sw",
            "arrows-double-nw-se",
            "arrows-double-se-nw",
            "arrows-double-sw-ne",
            "arrows-down-up",
            "arrows-down",
            "arrows-exchange-2",
            "arrows-exchange",
            "arrows-horizontal",
            "arrows-join-2",
            "arrows-join",
            "arrows-left-down",
            "arrows-left-right",
            "arrows-left",
            "arrows-maximize",
            "arrows-minimize",
            "arrows-move-horizontal",
            "arrows-move-vertical",
            "arrows-move",
            "arrows-random",
            "arrows-right-down",
            "arrows-right-left",
            "arrows-right",
            "arrows-shuffle-2",
            "arrows-shuffle",
            "arrows-sort",
            "arrows-split-2",
            "arrows-split",
            "arrows-transfer-down",
            "arrows-transfer-up",
            "arrows-up-down",
            "arrows-up-left",
            "arrows-up-right",
            "arrows-up",
            "arrows-vertical",
            "artboard-off",
            "artboard",
            "article-filled-filled",
            "article-off",
            "article",
            "aspect-ratio-filled",
            "aspect-ratio-off",
            "aspect-ratio",
            "assembly-off",
            "assembly",
            "asset",
            "asterisk-simple",
            "asterisk",
            "at-off",
            "at",
            "atom-2-filled",
            "atom-2",
            "atom-off",
            "atom",
            "augmented-reality-2",
            "augmented-reality-off",
            "augmented-reality",
            "award-filled",
            "award-off",
            "award",
            "axe",
            "axis-x",
            "axis-y",
            "baby-bottle",
            "baby-carriage",
            "backhoe",
            "backpack-off",
            "backpack",
            "backspace-filled",
            "backspace",
            "badge-3d",
            "badge-4k",
            "badge-8k",
            "badge-ad",
            "badge-ar",
            "badge-cc",
            "badge-filled",
            "badge-hd",
            "badge-off",
            "badge-sd",
            "badge-tm",
            "badge-vo",
            "badge-vr",
            "badge-wc",
            "badge",
            "badges-filled",
            "badges-off",
            "badges",
            "baguette",
            "ball-american-football-off",
            "ball-american-football",
            "ball-baseball",
            "ball-basketball",
            "ball-bowling",
            "ball-football-off",
            "ball-football",
            "ball-tennis",
            "ball-volleyball",
            "balloon-off",
            "balloon",
            "ballpen-off",
            "ballpen",
            "ban",
            "bandage-filled",
            "bandage-off",
            "bandage",
            "barbell-off",
            "barbell",
            "barcode-off",
            "barcode",
            "barrel-off",
            "barrel",
            "barrier-block-off",
            "barrier-block",
            "baseline-density-large",
            "baseline-density-medium",
            "baseline-density-small",
            "baseline",
            "basket-filled",
            "basket-off",
            "basket",
            "bat",
            "bath-filled",
            "bath-off",
            "bath",
            "battery-1-filled",
            "battery-1",
            "battery-2-filled",
            "battery-2",
            "battery-3-filled",
            "battery-3",
            "battery-4-filled",
            "battery-4",
            "battery-automotive",
            "battery-charging-2",
            "battery-charging",
            "battery-eco",
            "battery-filled",
            "battery-off",
            "battery",
            "beach-off",
            "beach",
            "bed-filled",
            "bed-off",
            "bed",
            "beer-filled",
            "beer-off",
            "beer",
            "bell-bolt",
            "bell-cancel",
            "bell-check",
            "bell-code",
            "bell-cog",
            "bell-dollar",
            "bell-down",
            "bell-exclamation",
            "bell-filled",
            "bell-heart",
            "bell-minus-filled",
            "bell-minus",
            "bell-off",
            "bell-pause",
            "bell-pin",
            "bell-plus-filled",
            "bell-plus",
            "bell-question",
            "bell-ringing-2-filled",
            "bell-ringing-2",
            "bell-ringing-filled",
            "bell-ringing",
            "bell-school",
            "bell-search",
            "bell-share",
            "bell-star",
            "bell-up",
            "bell-x-filled",
            "bell-x",
            "bell-z-filled",
            "bell-z",
            "bell",
            "beta",
            "bible",
            "bike-off",
            "bike",
            "binary-off",
            "binary-tree-2",
            "binary-tree",
            "binary",
            "biohazard-off",
            "biohazard",
            "blade-filled",
            "blade",
            "bleach-chlorine",
            "bleach-no-chlorine",
            "bleach-off",
            "bleach",
            "blockquote",
            "bluetooth-connected",
            "bluetooth-off",
            "bluetooth-x",
            "bluetooth",
            "blur-off",
            "blur",
            "bmp",
            "bold-off",
            "bold",
            "bolt-off",
            "bolt",
            "bomb",
            "bone-off",
            "bone",
            "bong-off",
            "bong",
            "book-2",
            "book-download",
            "book-off",
            "book-upload",
            "book",
            "bookmark-off",
            "bookmark",
            "bookmarks-off",
            "bookmarks",
            "books-off",
            "books",
            "border-all",
            "border-bottom",
            "border-corners",
            "border-horizontal",
            "border-inner",
            "border-left",
            "border-none",
            "border-outer",
            "border-radius",
            "border-right",
            "border-sides",
            "border-style-2",
            "border-style",
            "border-top",
            "border-vertical",
            "bottle-off",
            "bottle",
            "bounce-left",
            "bounce-right",
            "bow",
            "bowl",
            "box-align-bottom-left",
            "box-align-bottom-right",
            "box-align-bottom",
            "box-align-left",
            "box-align-right",
            "box-align-top-left",
            "box-align-top-right",
            "box-align-top",
            "box-margin",
            "box-model-2-off",
            "box-model-2",
            "box-model-off",
            "box-model",
            "box-multiple-0",
            "box-multiple-1",
            "box-multiple-2",
            "box-multiple-3",
            "box-multiple-4",
            "box-multiple-5",
            "box-multiple-6",
            "box-multiple-7",
            "box-multiple-8",
            "box-multiple-9",
            "box-multiple",
            "box-off",
            "box-padding",
            "box-seam",
            "box",
            "braces-off",
            "braces",
            "brackets-contain-end",
            "brackets-contain-start",
            "brackets-contain",
            "brackets-off",
            "brackets",
            "braille",
            "brain",
            "brand-4chan",
            "brand-abstract",
            "brand-adobe",
            "brand-adonis-js",
            "brand-airbnb",
            "brand-airtable",
            "brand-algolia",
            "brand-alipay",
            "brand-alpine-js",
            "brand-amazon",
            "brand-amd",
            "brand-amigo",
            "brand-among-us",
            "brand-android",
            "brand-angular",
            "brand-ao3",
            "brand-appgallery",
            "brand-apple-arcade",
            "brand-apple-podcast",
            "brand-apple",
            "brand-appstore",
            "brand-asana",
            "brand-backbone",
            "brand-badoo",
            "brand-baidu",
            "brand-bandcamp",
            "brand-bandlab",
            "brand-beats",
            "brand-behance",
            "brand-bilibili",
            "brand-binance",
            "brand-bing",
            "brand-bitbucket",
            "brand-blackberry",
            "brand-blender",
            "brand-blogger",
            "brand-booking",
            "brand-bootstrap",
            "brand-bulma",
            "brand-bumble",
            "brand-bunpo",
            "brand-c-sharp",
            "brand-cake",
            "brand-cakephp",
            "brand-campaignmonitor",
            "brand-carbon",
            "brand-cashapp",
            "brand-chrome",
            "brand-citymapper",
            "brand-codecov",
            "brand-codepen",
            "brand-codesandbox",
            "brand-cohost",
            "brand-coinbase",
            "brand-comedy-central",
            "brand-coreos",
            "brand-couchdb",
            "brand-couchsurfing",
            "brand-cpp",
            "brand-crunchbase",
            "brand-css3",
            "brand-ctemplar",
            "brand-cucumber",
            "brand-cupra",
            "brand-cypress",
            "brand-d3",
            "brand-days-counter",
            "brand-dcos",
            "brand-debian",
            "brand-deezer",
            "brand-deliveroo",
            "brand-deno",
            "brand-denodo",
            "brand-deviantart",
            "brand-dingtalk",
            "brand-discord-filled",
            "brand-discord",
            "brand-disney",
            "brand-disqus",
            "brand-django",
            "brand-docker",
            "brand-doctrine",
            "brand-dolby-digital",
            "brand-douban",
            "brand-dribbble-filled",
            "brand-dribbble",
            "brand-drops",
            "brand-drupal",
            "brand-edge",
            "brand-elastic",
            "brand-ember",
            "brand-envato",
            "brand-etsy",
            "brand-evernote",
            "brand-facebook-filled",
            "brand-facebook",
            "brand-figma",
            "brand-finder",
            "brand-firebase",
            "brand-firefox",
            "brand-fiverr",
            "brand-flickr",
            "brand-flightradar24",
            "brand-flipboard",
            "brand-flutter",
            "brand-fortnite",
            "brand-foursquare",
            "brand-framer-motion",
            "brand-framer",
            "brand-funimation",
            "brand-gatsby",
            "brand-git",
            "brand-github-copilot",
            "brand-github-filled",
            "brand-github",
            "brand-gitlab",
            "brand-gmail",
            "brand-golang",
            "brand-google-analytics",
            "brand-google-big-query",
            "brand-google-drive",
            "brand-google-fit",
            "brand-google-home",
            "brand-google-one",
            "brand-google-photos",
            "brand-google-play",
            "brand-google-podcasts",
            "brand-google",
            "brand-grammarly",
            "brand-graphql",
            "brand-gravatar",
            "brand-grindr",
            "brand-guardian",
            "brand-gumroad",
            "brand-hbo",
            "brand-headlessui",
            "brand-hipchat",
            "brand-html5",
            "brand-inertia",
            "brand-instagram",
            "brand-intercom",
            "brand-itch",
            "brand-javascript",
            "brand-juejin",
            "brand-kick",
            "brand-kickstarter",
            "brand-kotlin",
            "brand-laravel",
            "brand-lastfm",
            "brand-letterboxd",
            "brand-line",
            "brand-linkedin",
            "brand-linktree",
            "brand-linqpad",
            "brand-loom",
            "brand-mailgun",
            "brand-mantine",
            "brand-mastercard",
            "brand-mastodon",
            "brand-matrix",
            "brand-mcdonalds",
            "brand-medium",
            "brand-mercedes",
            "brand-messenger",
            "brand-meta",
            "brand-miniprogram",
            "brand-mixpanel",
            "brand-monday",
            "brand-mongodb",
            "brand-my-oppo",
            "brand-mysql",
            "brand-national-geographic",
            "brand-nem",
            "brand-netbeans",
            "brand-netease-music",
            "brand-netflix",
            "brand-nexo",
            "brand-nextcloud",
            "brand-nextjs",
            "brand-nord-vpn",
            "brand-notion",
            "brand-npm",
            "brand-nuxt",
            "brand-nytimes",
            "brand-office",
            "brand-ok-ru",
            "brand-onedrive",
            "brand-onlyfans",
            "brand-open-source",
            "brand-openai",
            "brand-openvpn",
            "brand-opera",
            "brand-pagekit",
            "brand-patreon",
            "brand-paypal-filled",
            "brand-paypal",
            "brand-paypay",
            "brand-peanut",
            "brand-pepsi",
            "brand-php",
            "brand-picsart",
            "brand-pinterest",
            "brand-planetscale",
            "brand-pocket",
            "brand-polymer",
            "brand-powershell",
            "brand-prisma",
            "brand-producthunt",
            "brand-pushbullet",
            "brand-pushover",
            "brand-python",
            "brand-qq",
            "brand-radix-ui",
            "brand-react-native",
            "brand-react",
            "brand-reason",
            "brand-reddit",
            "brand-redhat",
            "brand-redux",
            "brand-revolut",
            "brand-safari",
            "brand-samsungpass",
            "brand-sass",
            "brand-sentry",
            "brand-sharik",
            "brand-shazam",
            "brand-shopee",
            "brand-sketch",
            "brand-skype",
            "brand-slack",
            "brand-snapchat",
            "brand-snapseed",
            "brand-snowflake",
            "brand-socket-io",
            "brand-solidjs",
            "brand-soundcloud",
            "brand-spacehey",
            "brand-speedtest",
            "brand-spotify",
            "brand-stackoverflow",
            "brand-stackshare",
            "brand-steam",
            "brand-storybook",
            "brand-storytel",
            "brand-strava",
            "brand-stripe",
            "brand-sublime-text",
            "brand-sugarizer",
            "brand-supabase",
            "brand-superhuman",
            "brand-supernova",
            "brand-surfshark",
            "brand-svelte",
            "brand-symfony",
            "brand-tabler",
            "brand-tailwind",
            "brand-taobao",
            "brand-ted",
            "brand-telegram",
            "brand-tether",
            "brand-threejs",
            "brand-tidal",
            "brand-tikto-filled",
            "brand-tiktok",
            "brand-tinder",
            "brand-topbuzz",
            "brand-torchain",
            "brand-toyota",
            "brand-trello",
            "brand-tripadvisor",
            "brand-tumblr",
            "brand-twilio",
            "brand-twitch",
            "brand-twitter-filled",
            "brand-twitter",
            "brand-typescript",
            "brand-uber",
            "brand-ubuntu",
            "brand-unity",
            "brand-unsplash",
            "brand-upwork",
            "brand-valorant",
            "brand-vercel",
            "brand-vimeo",
            "brand-vinted",
            "brand-visa",
            "brand-visual-studio",
            "brand-vite",
            "brand-vivaldi",
            "brand-vk",
            "brand-volkswagen",
            "brand-vsco",
            "brand-vscode",
            "brand-vue",
            "brand-walmart",
            "brand-waze",
            "brand-webflow",
            "brand-wechat",
            "brand-weibo",
            "brand-whatsapp",
            "brand-windows",
            "brand-windy",
            "brand-wish",
            "brand-wix",
            "brand-wordpress",
            "brand-xbox",
            "brand-xing",
            "brand-yahoo",
            "brand-yatse",
            "brand-ycombinator",
            "brand-youtube-kids",
            "brand-youtube",
            "brand-zalando",
            "brand-zapier",
            "brand-zeit",
            "brand-zhihu",
            "brand-zoom",
            "brand-zulip",
            "brand-zwift",
            "bread-off",
            "bread",
            "briefcase-off",
            "briefcase",
            "brightness-2",
            "brightness-down",
            "brightness-half",
            "brightness-off",
            "brightness-up",
            "brightness",
            "broadcast-off",
            "broadcast",
            "browser-check",
            "browser-off",
            "browser-plus",
            "browser-x",
            "browser",
            "brush-off",
            "brush",
            "bucket-droplet",
            "bucket-off",
            "bucket",
            "bug-off",
            "bug",
            "building-arch",
            "building-bank",
            "building-bridge-2",
            "building-bridge",
            "building-broadcast-tower",
            "building-carousel",
            "building-castle",
            "building-church",
            "building-circus",
            "building-community",
            "building-cottage",
            "building-estate",
            "building-factory-2",
            "building-factory",
            "building-fortress",
            "building-hospital",
            "building-lighthouse",
            "building-monument",
            "building-pavilion",
            "building-skyscraper",
            "building-stadium",
            "building-store",
            "building-tunnel",
            "building-warehouse",
            "building-wind-turbine",
            "building",
            "bulb-filled",
            "bulb-off",
            "bulb",
            "bulldozer",
            "bus-off",
            "bus-stop",
            "bus",
            "businessplan",
            "butterfly",
            "cactus-off",
            "cactus",
            "cake-off",
            "cake",
            "calculator-off",
            "calculator",
            "calendar-bolt",
            "calendar-cancel",
            "calendar-check",
            "calendar-code",
            "calendar-cog",
            "calendar-dollar",
            "calendar-down",
            "calendar-due",
            "calendar-event",
            "calendar-exclamation",
            "calendar-heart",
            "calendar-minus",
            "calendar-off",
            "calendar-pause",
            "calendar-pin",
            "calendar-plus",
            "calendar-question",
            "calendar-search",
            "calendar-share",
            "calendar-star",
            "calendar-stats",
            "calendar-time",
            "calendar-up",
            "calendar-x",
            "calendar",
            "camera-bolt",
            "camera-cancel",
            "camera-check",
            "camera-code",
            "camera-cog",
            "camera-dollar",
            "camera-down",
            "camera-exclamation",
            "camera-filled",
            "camera-heart",
            "camera-minus",
            "camera-off",
            "camera-pause",
            "camera-pin",
            "camera-plus",
            "camera-question",
            "camera-rotate",
            "camera-search",
            "camera-selfie",
            "camera-share",
            "camera-star",
            "camera-up",
            "camera-x",
            "camera",
            "camper",
            "campfire",
            "candle",
            "candy-off",
            "candy",
            "cane",
            "cannabis",
            "capture-off",
            "capture",
            "car-crane",
            "car-crash",
            "car-off",
            "car-turbine",
            "car",
            "caravan",
            "cardboards-off",
            "cardboards",
            "cards",
            "caret-down",
            "caret-left",
            "caret-right",
            "caret-up",
            "carousel-horizontal",
            "carousel-vertical",
            "carrot-off",
            "carrot",
            "cash-banknote-off",
            "cash-banknote",
            "cash-off",
            "cash",
            "cast-off",
            "cast",
            "cat",
            "category-2",
            "category",
            "ce-off",
            "ce",
            "cell-signal-1",
            "cell-signal-2",
            "cell-signal-3",
            "cell-signal-4",
            "cell-signal-5",
            "cell-signal-off",
            "cell",
            "certificate-2-off",
            "certificate-2",
            "certificate-off",
            "certificate",
            "chair-director",
            "chalkboard-off",
            "chalkboard",
            "charging-pile",
            "chart-arcs-3",
            "chart-arcs",
            "chart-area-filled",
            "chart-area-line-filled",
            "chart-area-line",
            "chart-area",
            "chart-arrows-vertical",
            "chart-arrows",
            "chart-bar-off",
            "chart-bar",
            "chart-bubble-filled",
            "chart-bubble",
            "chart-candle-filled",
            "chart-candle",
            "chart-circles",
            "chart-donut-2",
            "chart-donut-3",
            "chart-donut-4",
            "chart-donut-filled",
            "chart-donut",
            "chart-dots-2",
            "chart-dots-3",
            "chart-dots",
            "chart-grid-dots",
            "chart-histogram",
            "chart-infographic",
            "chart-line",
            "chart-pie-2",
            "chart-pie-3",
            "chart-pie-4",
            "chart-pie-filled",
            "chart-pie-off",
            "chart-pie",
            "chart-ppf",
            "chart-radar",
            "chart-sankey",
            "chart-treemap",
            "check",
            "checkbox",
            "checklist",
            "checks",
            "checkup-list",
            "cheese",
            "chef-hat-off",
            "chef-hat",
            "cherry-filled",
            "cherry",
            "chess-bishop-filled",
            "chess-bishop",
            "chess-filled",
            "chess-king-filled",
            "chess-king",
            "chess-knight-filled",
            "chess-knight",
            "chess-queen-filled",
            "chess-queen",
            "chess-rook-filled",
            "chess-rook",
            "chess",
            "chevron-down-left",
            "chevron-down-right",
            "chevron-down",
            "chevron-left",
            "chevron-right",
            "chevron-up-left",
            "chevron-up-right",
            "chevron-up",
            "chevrons-down-left",
            "chevrons-down-right",
            "chevrons-down",
            "chevrons-left",
            "chevrons-right",
            "chevrons-up-left",
            "chevrons-up-right",
            "chevrons-up",
            "chisel",
            "christmas-tree-off",
            "christmas-tree",
            "circle-0-filled",
            "circle-1-filled",
            "circle-2-filled",
            "circle-3-filled",
            "circle-4-filled",
            "circle-5-filled",
            "circle-6-filled",
            "circle-7-filled",
            "circle-8-filled",
            "circle-9-filled",
            "circle-arrow-down-filled",
            "circle-arrow-down-left-filled",
            "circle-arrow-down-left",
            "circle-arrow-down-right-filled",
            "circle-arrow-down-right",
            "circle-arrow-down",
            "circle-arrow-left-filled",
            "circle-arrow-left",
            "circle-arrow-right-filled",
            "circle-arrow-right",
            "circle-arrow-up-filled",
            "circle-arrow-up-left-filled",
            "circle-arrow-up-left",
            "circle-arrow-up-right-filled",
            "circle-arrow-up-right",
            "circle-arrow-up",
            "circle-caret-down",
            "circle-caret-left",
            "circle-caret-right",
            "circle-caret-up",
            "circle-check-filled",
            "circle-check",
            "circle-chevron-down",
            "circle-chevron-left",
            "circle-chevron-right",
            "circle-chevron-up",
            "circle-chevrons-down",
            "circle-chevrons-left",
            "circle-chevrons-right",
            "circle-chevrons-up",
            "circle-dashed",
            "circle-dot-filled",
            "circle-dot",
            "circle-dotted",
            "circle-filled",
            "circle-half-2",
            "circle-half-vertical",
            "circle-half",
            "circle-key-filled",
            "circle-key",
            "circle-letter-a",
            "circle-letter-b",
            "circle-letter-c",
            "circle-letter-d",
            "circle-letter-e",
            "circle-letter-f",
            "circle-letter-g",
            "circle-letter-h",
            "circle-letter-i",
            "circle-letter-j",
            "circle-letter-k",
            "circle-letter-l",
            "circle-letter-m",
            "circle-letter-n",
            "circle-letter-o",
            "circle-letter-p",
            "circle-letter-q",
            "circle-letter-r",
            "circle-letter-s",
            "circle-letter-t",
            "circle-letter-u",
            "circle-letter-v",
            "circle-letter-w",
            "circle-letter-x",
            "circle-letter-y",
            "circle-letter-z",
            "circle-minus",
            "circle-number-0",
            "circle-number-1",
            "circle-number-2",
            "circle-number-3",
            "circle-number-4",
            "circle-number-5",
            "circle-number-6",
            "circle-number-7",
            "circle-number-8",
            "circle-number-9",
            "circle-off",
            "circle-plus",
            "circle-rectangle-off",
            "circle-rectangle",
            "circle-square",
            "circle-triangle",
            "circle-x-filled",
            "circle-x",
            "circle",
            "circles-filled",
            "circles-relation",
            "circles",
            "circuit-ammeter",
            "circuit-battery",
            "circuit-bulb",
            "circuit-capacitor-polarized",
            "circuit-capacitor",
            "circuit-cell-plus",
            "circuit-cell",
            "circuit-changeover",
            "circuit-diode-zener",
            "circuit-diode",
            "circuit-ground-digital",
            "circuit-ground",
            "circuit-inductor",
            "circuit-motor",
            "circuit-pushbutton",
            "circuit-resistor",
            "circuit-switch-closed",
            "circuit-switch-open",
            "circuit-voltmeter",
            "clear-all",
            "clear-formatting",
            "click",
            "clipboard-check",
            "clipboard-copy",
            "clipboard-data",
            "clipboard-heart",
            "clipboard-list",
            "clipboard-off",
            "clipboard-plus",
            "clipboard-text",
            "clipboard-typography",
            "clipboard-x",
            "clipboard",
            "clock-2",
            "clock-bolt",
            "clock-cancel",
            "clock-check",
            "clock-code",
            "clock-cog",
            "clock-dollar",
            "clock-down",
            "clock-edit",
            "clock-exclamation",
            "clock-filled",
            "clock-heart",
            "clock-hour-1",
            "clock-hour-10",
            "clock-hour-11",
            "clock-hour-12",
            "clock-hour-2",
            "clock-hour-3",
            "clock-hour-4",
            "clock-hour-5",
            "clock-hour-6",
            "clock-hour-7",
            "clock-hour-8",
            "clock-hour-9",
            "clock-minus",
            "clock-off",
            "clock-pause",
            "clock-pin",
            "clock-play",
            "clock-plus",
            "clock-question",
            "clock-record",
            "clock-search",
            "clock-share",
            "clock-shield",
            "clock-star",
            "clock-stop",
            "clock-up",
            "clock-x",
            "clock",
            "clothes-rack-off",
            "clothes-rack",
            "cloud-bolt",
            "cloud-cancel",
            "cloud-check",
            "cloud-code",
            "cloud-cog",
            "cloud-computing",
            "cloud-data-connection",
            "cloud-dollar",
            "cloud-down",
            "cloud-download",
            "cloud-exclamation",
            "cloud-filled",
            "cloud-fog",
            "cloud-heart",
            "cloud-lock-open",
            "cloud-lock",
            "cloud-minus",
            "cloud-off",
            "cloud-pause",
            "cloud-pin",
            "cloud-plus",
            "cloud-question",
            "cloud-rain",
            "cloud-search",
            "cloud-share",
            "cloud-snow",
            "cloud-star",
            "cloud-storm",
            "cloud-up",
            "cloud-upload",
            "cloud-x",
            "cloud",
            "clover-2",
            "clover",
            "clubs-filled",
            "clubs",
            "code-asterix",
            "code-circle-2",
            "code-circle",
            "code-dots",
            "code-minus",
            "code-off",
            "code-plus",
            "code",
            "coffee-off",
            "coffee",
            "coffin",
            "coin-bitcoin",
            "coin-euro",
            "coin-monero",
            "coin-off",
            "coin-pound",
            "coin-rupee",
            "coin-yen",
            "coin-yuan",
            "coin",
            "coins",
            "color-filter",
            "color-picker-off",
            "color-picker",
            "color-swatch-off",
            "color-swatch",
            "column-insert-left",
            "column-insert-right",
            "columns-1",
            "columns-2",
            "columns-3",
            "columns-off",
            "columns",
            "comet",
            "command-off",
            "command",
            "compass-off",
            "compass",
            "components-off",
            "components",
            "cone-2",
            "cone-off",
            "cone",
            "confetti-off",
            "confetti",
            "confucius",
            "container-off",
            "container",
            "contrast-2-off",
            "contrast-2",
            "contrast-off",
            "contrast",
            "cooker",
            "cookie-man",
            "cookie-off",
            "cookie",
            "copy-off",
            "copy",
            "copyleft-filled",
            "copyleft-off",
            "copyleft",
            "copyright-filled",
            "copyright-off",
            "copyright",
            "corner-down-left-double",
            "corner-down-left",
            "corner-down-right-double",
            "corner-down-right",
            "corner-left-down-double",
            "corner-left-down",
            "corner-left-up-double",
            "corner-left-up",
            "corner-right-down-double",
            "corner-right-down",
            "corner-right-up-double",
            "corner-right-up",
            "corner-up-left-double",
            "corner-up-left",
            "corner-up-right-double",
            "corner-up-right",
            "cpu-2",
            "cpu-off",
            "cpu",
            "crane-off",
            "crane",
            "creative-commons-by",
            "creative-commons-nc",
            "creative-commons-nd",
            "creative-commons-off",
            "creative-commons-sa",
            "creative-commons-zero",
            "creative-commons",
            "credit-card-off",
            "credit-card",
            "cricket",
            "crop",
            "cross-filled",
            "cross-off",
            "cross",
            "crosshair",
            "crown-off",
            "crown",
            "crutches-off",
            "crutches",
            "crystal-ball",
            "csv",
            "cube-send",
            "cube-unfolded",
            "cup-off",
            "cup",
            "curling",
            "curly-loop",
            "currency-afghani",
            "currency-bahraini",
            "currency-baht",
            "currency-bitcoin",
            "currency-cent",
            "currency-dinar",
            "currency-dirham",
            "currency-dogecoin",
            "currency-dollar-australian",
            "currency-dollar-brunei",
            "currency-dollar-canadian",
            "currency-dollar-guyanese",
            "currency-dollar-off",
            "currency-dollar-singapore",
            "currency-dollar-zimbabwean",
            "currency-dollar",
            "currency-dong",
            "currency-dram",
            "currency-ethereum",
            "currency-euro-off",
            "currency-euro",
            "currency-forint",
            "currency-frank",
            "currency-guarani",
            "currency-hryvnia",
            "currency-kip",
            "currency-krone-czech",
            "currency-krone-danish",
            "currency-krone-swedish",
            "currency-lari",
            "currency-leu",
            "currency-lira",
            "currency-litecoin",
            "currency-lyd",
            "currency-manat",
            "currency-monero",
            "currency-naira",
            "currency-nano",
            "currency-off",
            "currency-paanga",
            "currency-peso",
            "currency-pound-off",
            "currency-pound",
            "currency-quetzal",
            "currency-real",
            "currency-renminbi",
            "currency-ripple",
            "currency-riyal",
            "currency-rubel",
            "currency-rufiyaa",
            "currency-rupee-nepalese",
            "currency-rupee",
            "currency-shekel",
            "currency-solana",
            "currency-som",
            "currency-taka",
            "currency-tenge",
            "currency-tugrik",
            "currency-won",
            "currency-yen-off",
            "currency-yen",
            "currency-yuan",
            "currency-zloty",
            "currency",
            "current-location-off",
            "current-location",
            "cursor-off",
            "cursor-text",
            "cut",
            "cylinder",
            "dashboard-off",
            "dashboard",
            "database-cog",
            "database-dollar",
            "database-edit",
            "database-exclamation",
            "database-export",
            "database-heart",
            "database-import",
            "database-leak",
            "database-minus",
            "database-off",
            "database-plus",
            "database-search",
            "database-share",
            "database-star",
            "database-x",
            "database",
            "decimal",
            "deer",
            "delta",
            "dental-broken",
            "dental-off",
            "dental",
            "deselect",
            "details-off",
            "details",
            "device-airpods-case",
            "device-airpods",
            "device-analytics",
            "device-audio-tape",
            "device-camera-phone",
            "device-cctv-off",
            "device-cctv",
            "device-computer-camera-off",
            "device-computer-camera",
            "device-desktop-analytics",
            "device-desktop-bolt",
            "device-desktop-cancel",
            "device-desktop-check",
            "device-desktop-code",
            "device-desktop-cog",
            "device-desktop-dollar",
            "device-desktop-down",
            "device-desktop-exclamation",
            "device-desktop-heart",
            "device-desktop-minus",
            "device-desktop-off",
            "device-desktop-pause",
            "device-desktop-pin",
            "device-desktop-plus",
            "device-desktop-question",
            "device-desktop-search",
            "device-desktop-share",
            "device-desktop-star",
            "device-desktop-up",
            "device-desktop-x",
            "device-desktop",
            "device-floppy",
            "device-gamepad-2",
            "device-gamepad",
            "device-heart-monitor-filled",
            "device-heart-monitor",
            "device-imac-bolt",
            "device-imac-cancel",
            "device-imac-check",
            "device-imac-code",
            "device-imac-cog",
            "device-imac-dollar",
            "device-imac-down",
            "device-imac-exclamation",
            "device-imac-heart",
            "device-imac-minus",
            "device-imac-off",
            "device-imac-pause",
            "device-imac-pin",
            "device-imac-plus",
            "device-imac-question",
            "device-imac-search",
            "device-imac-share",
            "device-imac-star",
            "device-imac-up",
            "device-imac-x",
            "device-imac",
            "device-ipad-bolt",
            "device-ipad-cancel",
            "device-ipad-check",
            "device-ipad-code",
            "device-ipad-cog",
            "device-ipad-dollar",
            "device-ipad-down",
            "device-ipad-exclamation",
            "device-ipad-heart",
            "device-ipad-horizontal-bolt",
            "device-ipad-horizontal-cancel",
            "device-ipad-horizontal-check",
            "device-ipad-horizontal-code",
            "device-ipad-horizontal-cog",
            "device-ipad-horizontal-dollar",
            "device-ipad-horizontal-down",
            "device-ipad-horizontal-exclamation",
            "device-ipad-horizontal-heart",
            "device-ipad-horizontal-minus",
            "device-ipad-horizontal-off",
            "device-ipad-horizontal-pause",
            "device-ipad-horizontal-pin",
            "device-ipad-horizontal-plus",
            "device-ipad-horizontal-question",
            "device-ipad-horizontal-search",
            "device-ipad-horizontal-share",
            "device-ipad-horizontal-star",
            "device-ipad-horizontal-up",
            "device-ipad-horizontal-x",
            "device-ipad-horizontal",
            "device-ipad-minus",
            "device-ipad-off",
            "device-ipad-pause",
            "device-ipad-pin",
            "device-ipad-plus",
            "device-ipad-question",
            "device-ipad-search",
            "device-ipad-share",
            "device-ipad-star",
            "device-ipad-up",
            "device-ipad-x",
            "device-ipad",
            "device-landline-phone",
            "device-laptop-off",
            "device-laptop",
            "device-mobile-bolt",
            "device-mobile-cancel",
            "device-mobile-charging",
            "device-mobile-check",
            "device-mobile-code",
            "device-mobile-cog",
            "device-mobile-dollar",
            "device-mobile-down",
            "device-mobile-exclamation",
            "device-mobile-filled",
            "device-mobile-heart",
            "device-mobile-message",
            "device-mobile-minus",
            "device-mobile-off",
            "device-mobile-pause",
            "device-mobile-pin",
            "device-mobile-plus",
            "device-mobile-question",
            "device-mobile-rotated",
            "device-mobile-search",
            "device-mobile-share",
            "device-mobile-star",
            "device-mobile-up",
            "device-mobile-vibration",
            "device-mobile-x",
            "device-mobile",
            "device-nintendo-off",
            "device-nintendo",
            "device-remote",
            "device-sd-card",
            "device-sim-1",
            "device-sim-2",
            "device-sim-3",
            "device-sim",
            "device-speaker-off",
            "device-speaker",
            "device-tablet-bolt",
            "device-tablet-cancel",
            "device-tablet-check",
            "device-tablet-code",
            "device-tablet-cog",
            "device-tablet-dollar",
            "device-tablet-down",
            "device-tablet-exclamation",
            "device-tablet-filled",
            "device-tablet-heart",
            "device-tablet-minus",
            "device-tablet-off",
            "device-tablet-pause",
            "device-tablet-pin",
            "device-tablet-plus",
            "device-tablet-question",
            "device-tablet-search",
            "device-tablet-share",
            "device-tablet-star",
            "device-tablet-up",
            "device-tablet-x",
            "device-tablet",
            "device-tv-off",
            "device-tv-old",
            "device-tv",
            "device-watch-bolt",
            "device-watch-cancel",
            "device-watch-check",
            "device-watch-code",
            "device-watch-cog",
            "device-watch-dollar",
            "device-watch-down",
            "device-watch-exclamation",
            "device-watch-heart",
            "device-watch-minus",
            "device-watch-off",
            "device-watch-pause",
            "device-watch-pin",
            "device-watch-plus",
            "device-watch-question",
            "device-watch-search",
            "device-watch-share",
            "device-watch-star",
            "device-watch-stats-2",
            "device-watch-stats",
            "device-watch-up",
            "device-watch-x",
            "device-watch",
            "devices-2",
            "devices-bolt",
            "devices-cancel",
            "devices-check",
            "devices-code",
            "devices-cog",
            "devices-dollar",
            "devices-down",
            "devices-exclamation",
            "devices-heart",
            "devices-minus",
            "devices-off",
            "devices-pause",
            "devices-pc-off",
            "devices-pc",
            "devices-pin",
            "devices-plus",
            "devices-question",
            "devices-search",
            "devices-share",
            "devices-star",
            "devices-up",
            "devices-x",
            "devices",
            "dialpad-filled",
            "dialpad-off",
            "dialpad",
            "diamond-filled",
            "diamond-off",
            "diamond",
            "diamonds-filled",
            "diamonds",
            "dice-1-filled",
            "dice-1",
            "dice-2-filled",
            "dice-2",
            "dice-3-filled",
            "dice-3",
            "dice-4-filled",
            "dice-4",
            "dice-5-filled",
            "dice-5",
            "dice-6-filled",
            "dice-6",
            "dice-filled",
            "dice",
            "dimensions",
            "direction-horizontal",
            "direction-sign-filled",
            "direction-sign-off",
            "direction-sign",
            "direction",
            "directions-off",
            "directions",
            "disabled-2",
            "disabled-off",
            "disabled",
            "disc-golf",
            "disc-off",
            "disc",
            "discount-2-off",
            "discount-2",
            "discount-check-filled",
            "discount-check",
            "discount-off",
            "discount",
            "divide",
            "dna-2-off",
            "dna-2",
            "dna-off",
            "dna",
            "dog-bowl",
            "dog",
            "door-enter",
            "door-exit",
            "door-off",
            "door",
            "dots-circle-horizontal",
            "dots-diagonal-2",
            "dots-diagonal",
            "dots-vertical",
            "dots",
            "download-off",
            "download",
            "drag-drop-2",
            "drag-drop",
            "drone-off",
            "drone",
            "drop-circle",
            "droplet-bolt",
            "droplet-cancel",
            "droplet-check",
            "droplet-code",
            "droplet-cog",
            "droplet-dollar",
            "droplet-down",
            "droplet-exclamation",
            "droplet-filled-2",
            "droplet-filled",
            "droplet-half-2",
            "droplet-half-filled",
            "droplet-half",
            "droplet-heart",
            "droplet-minus",
            "droplet-off",
            "droplet-pause",
            "droplet-pin",
            "droplet-plus",
            "droplet-question",
            "droplet-search",
            "droplet-share",
            "droplet-star",
            "droplet-up",
            "droplet-x",
            "droplet",
            "e-passport",
            "ear-off",
            "ear",
            "ease-in-control-point",
            "ease-in-out-control-points",
            "ease-in-out",
            "ease-in",
            "ease-out-control-point",
            "ease-out",
            "edit-circle-off",
            "edit-circle",
            "edit-off",
            "edit",
            "egg-cracked",
            "egg-filled",
            "egg-fried",
            "egg-off",
            "egg",
            "eggs",
            "elevator-off",
            "elevator",
            "emergency-bed",
            "empathize-off",
            "empathize",
            "emphasis",
            "engine-off",
            "engine",
            "equal-double",
            "equal-not",
            "equal",
            "eraser-off",
            "eraser",
            "error-404-off",
            "error-404",
            "exchange-off",
            "exchange",
            "exclamation-circle",
            "exclamation-mark-off",
            "exclamation-mark",
            "explicit-off",
            "explicit",
            "exposure-0",
            "exposure-minus-1",
            "exposure-minus-2",
            "exposure-off",
            "exposure-plus-1",
            "exposure-plus-2",
            "exposure",
            "external-link-off",
            "external-link",
            "eye-check",
            "eye-closed",
            "eye-cog",
            "eye-edit",
            "eye-exclamation",
            "eye-filled",
            "eye-heart",
            "eye-off",
            "eye-table",
            "eye-x",
            "eye",
            "eyeglass-2",
            "eyeglass-off",
            "eyeglass",
            "face-id-error",
            "face-id",
            "face-mask-off",
            "face-mask",
            "fall",
            "feather-off",
            "feather",
            "fence-off",
            "fence",
            "fidget-spinner",
            "file-3d",
            "file-alert",
            "file-analytics",
            "file-arrow-left",
            "file-arrow-right",
            "file-barcode",
            "file-broken",
            "file-certificate",
            "file-chart",
            "file-check",
            "file-code-2",
            "file-code",
            "file-database",
            "file-delta",
            "file-description",
            "file-diff",
            "file-digit",
            "file-dislike",
            "file-dollar",
            "file-dots",
            "file-download",
            "file-euro",
            "file-export",
            "file-filled",
            "file-function",
            "file-horizontal",
            "file-import",
            "file-infinity",
            "file-info",
            "file-invoice",
            "file-lambda",
            "file-like",
            "file-minus",
            "file-music",
            "file-off",
            "file-orientation",
            "file-pencil",
            "file-percent",
            "file-phone",
            "file-plus",
            "file-power",
            "file-report",
            "file-rss",
            "file-scissors",
            "file-search",
            "file-settings",
            "file-shredder",
            "file-signal",
            "file-spreadsheet",
            "file-stack",
            "file-star",
            "file-symlink",
            "file-text-ai",
            "file-text",
            "file-time",
            "file-typography",
            "file-unknown",
            "file-upload",
            "file-vector",
            "file-x-filled",
            "file-x",
            "file-zip",
            "file",
            "files-off",
            "files",
            "filter-cog",
            "filter-dollar",
            "filter-edit",
            "filter-minus",
            "filter-off",
            "filter-plus",
            "filter-star",
            "filter-x",
            "filter",
            "filters",
            "fingerprint-off",
            "fingerprint",
            "fire-hydrant-off",
            "fire-hydrant",
            "firetruck",
            "first-aid-kit-off",
            "first-aid-kit",
            "fish-bone",
            "fish-christianity",
            "fish-hook-off",
            "fish-hook",
            "fish-off",
            "fish",
            "flag-2-filled",
            "flag-2-off",
            "flag-2",
            "flag-3-filled",
            "flag-3",
            "flag-filled",
            "flag-off",
            "flag",
            "flame-off",
            "flame",
            "flare",
            "flask-2-off",
            "flask-2",
            "flask-off",
            "flask",
            "flip-flops",
            "flip-horizontal",
            "flip-vertical",
            "float-center",
            "float-left",
            "float-none",
            "float-right",
            "flower-off",
            "flower",
            "focus-2",
            "focus-centered",
            "focus",
            "fold-down",
            "fold-up",
            "fold",
            "folder-bolt",
            "folder-cancel",
            "folder-check",
            "folder-code",
            "folder-cog",
            "folder-dollar",
            "folder-down",
            "folder-exclamation",
            "folder-filled",
            "folder-heart",
            "folder-minus",
            "folder-off",
            "folder-pause",
            "folder-pin",
            "folder-plus",
            "folder-question",
            "folder-search",
            "folder-share",
            "folder-star",
            "folder-symlink",
            "folder-up",
            "folder-x",
            "folder",
            "folders-off",
            "folders",
            "forbid-2",
            "forbid",
            "forklift",
            "forms",
            "fountain-off",
            "fountain",
            "frame-off",
            "frame",
            "free-rights",
            "fridge-off",
            "fridge",
            "friends-off",
            "friends",
            "function-off",
            "function",
            "garden-cart-off",
            "garden-cart",
            "gas-station-off",
            "gas-station",
            "gauge-off",
            "gauge",
            "gavel",
            "gender-agender",
            "gender-androgyne",
            "gender-bigender",
            "gender-demiboy",
            "gender-demigirl",
            "gender-epicene",
            "gender-female",
            "gender-femme",
            "gender-genderfluid",
            "gender-genderless",
            "gender-genderqueer",
            "gender-hermaphrodite",
            "gender-intergender",
            "gender-male",
            "gender-neutrois",
            "gender-third",
            "gender-transgender",
            "gender-trasvesti",
            "geometry",
            "ghost-2-filled",
            "ghost-2",
            "ghost-filled",
            "ghost-off",
            "ghost",
            "gif",
            "gift-card",
            "gift-off",
            "gift",
            "git-branch-deleted",
            "git-branch",
            "git-cherry-pick",
            "git-commit",
            "git-compare",
            "git-fork",
            "git-merge",
            "git-pull-request-closed",
            "git-pull-request-draft",
            "git-pull-request",
            "gizmo",
            "glass-full",
            "glass-off",
            "glass",
            "globe-off",
            "globe",
            "go-game",
            "golf-off",
            "golf",
            "gps",
            "gradienter",
            "grain",
            "graph-off",
            "graph",
            "grave-2",
            "grave",
            "grid-dots",
            "grid-pattern",
            "grill-fork",
            "grill-off",
            "grill-spatula",
            "grill",
            "grip-horizontal",
            "grip-vertical",
            "growth",
            "guitar-pick-filled",
            "guitar-pick",
            "h-1",
            "h-2",
            "h-3",
            "h-4",
            "h-5",
            "h-6",
            "hammer-off",
            "hammer",
            "hand-click",
            "hand-finger-off",
            "hand-finger",
            "hand-grab",
            "hand-little-finger",
            "hand-middle-finger",
            "hand-move",
            "hand-off",
            "hand-ring-finger",
            "hand-rock",
            "hand-sanitizer",
            "hand-stop",
            "hand-three-fingers",
            "hand-two-fingers",
            "hanger-2",
            "hanger-off",
            "hanger",
            "hash",
            "haze",
            "heading-off",
            "heading",
            "headphones-filled",
            "headphones-off",
            "headphones",
            "headset-off",
            "headset",
            "health-recognition",
            "heart-broken",
            "heart-filled",
            "heart-handshake",
            "heart-minus",
            "heart-off",
            "heart-plus",
            "heart-rate-monitor",
            "heart",
            "heartbeat",
            "hearts-off",
            "hearts",
            "helicopter-landing",
            "helicopter",
            "helmet-off",
            "helmet",
            "help-circle-filled",
            "help-circle",
            "help-hexagon-filled",
            "help-hexagon",
            "help-octagon-filled",
            "help-octagon",
            "help-off",
            "help-small",
            "help-square-filled",
            "help-square-rounded-filled",
            "help-square-rounded",
            "help-square",
            "help-triangle-filled",
            "help-triangle",
            "help",
            "hexagon-0-filled",
            "hexagon-1-filled",
            "hexagon-2-filled",
            "hexagon-3-filled",
            "hexagon-3d",
            "hexagon-4-filled",
            "hexagon-5-filled",
            "hexagon-6-filled",
            "hexagon-7-filled",
            "hexagon-8-filled",
            "hexagon-9-filled",
            "hexagon-filled",
            "hexagon-letter-a",
            "hexagon-letter-b",
            "hexagon-letter-c",
            "hexagon-letter-d",
            "hexagon-letter-e",
            "hexagon-letter-f",
            "hexagon-letter-g",
            "hexagon-letter-h",
            "hexagon-letter-i",
            "hexagon-letter-j",
            "hexagon-letter-k",
            "hexagon-letter-l",
            "hexagon-letter-m",
            "hexagon-letter-n",
            "hexagon-letter-o",
            "hexagon-letter-p",
            "hexagon-letter-q",
            "hexagon-letter-r",
            "hexagon-letter-s",
            "hexagon-letter-t",
            "hexagon-letter-u",
            "hexagon-letter-v",
            "hexagon-letter-w",
            "hexagon-letter-x",
            "hexagon-letter-y",
            "hexagon-letter-z",
            "hexagon-number-0",
            "hexagon-number-1",
            "hexagon-number-2",
            "hexagon-number-3",
            "hexagon-number-4",
            "hexagon-number-5",
            "hexagon-number-6",
            "hexagon-number-7",
            "hexagon-number-8",
            "hexagon-number-9",
            "hexagon-off",
            "hexagon",
            "hexagons-off",
            "hexagons",
            "hierarchy-2",
            "hierarchy-3",
            "hierarchy-off",
            "hierarchy",
            "highlight-off",
            "highlight",
            "history-off",
            "history-toggle",
            "history",
            "home-2",
            "home-bolt",
            "home-cancel",
            "home-check",
            "home-cog",
            "home-dollar",
            "home-dot",
            "home-down",
            "home-eco",
            "home-edit",
            "home-exclamation",
            "home-hand",
            "home-heart",
            "home-infinity",
            "home-link",
            "home-minus",
            "home-move",
            "home-off",
            "home-plus",
            "home-question",
            "home-ribbon",
            "home-search",
            "home-share",
            "home-shield",
            "home-signal",
            "home-star",
            "home-stats",
            "home-up",
            "home-x",
            "home",
            "horse-toy",
            "hotel-service",
            "hourglass-empty",
            "hourglass-filled",
            "hourglass-high",
            "hourglass-low",
            "hourglass-off",
            "hourglass",
            "html",
            "http-connect",
            "http-delete",
            "http-get",
            "http-head",
            "http-options",
            "http-path",
            "http-post",
            "http-put",
            "http-trace",
            "ice-cream-2",
            "ice-cream-off",
            "ice-cream",
            "ice-skating",
            "icons-off",
            "icons",
            "id-badge-2",
            "id-badge-off",
            "id-badge",
            "id-off",
            "id",
            "inbox-off",
            "inbox",
            "indent-decrease",
            "indent-increase",
            "infinity-off",
            "infinity",
            "info-circle-filled",
            "info-circle",
            "info-hexagon-filled",
            "info-hexagon",
            "info-octagon-filled",
            "info-octagon",
            "info-small",
            "info-square-filled",
            "info-square-rounded-filled",
            "info-square-rounded",
            "info-square",
            "info-triangle-filled",
            "info-triangle",
            "inner-shadow-bottom-filled",
            "inner-shadow-bottom-left-filled",
            "inner-shadow-bottom-left",
            "inner-shadow-bottom-right-filled",
            "inner-shadow-bottom-right",
            "inner-shadow-bottom",
            "inner-shadow-left-filled",
            "inner-shadow-left",
            "inner-shadow-right-filled",
            "inner-shadow-right",
            "inner-shadow-top-filled",
            "inner-shadow-top-left-filled",
            "inner-shadow-top-left",
            "inner-shadow-top-right-filled",
            "inner-shadow-top-right",
            "inner-shadow-top",
            "input-search",
            "ironing-1",
            "ironing-2",
            "ironing-3",
            "ironing-off",
            "ironing-steam-off",
            "ironing-steam",
            "italic",
            "jacket",
            "jetpack",
            "jewish-star-filled",
            "jewish-star",
            "jpg",
            "json",
            "jump-rope",
            "karate",
            "kayak",
            "kering",
            "key-off",
            "key",
            "keyboard-hide",
            "keyboard-off",
            "keyboard-show",
            "keyboard",
            "keyframe-align-center",
            "keyframe-align-horizontal",
            "keyframe-align-vertical",
            "keyframe",
            "keyframes",
            "ladder-off",
            "ladder",
            "lambda",
            "lamp-2",
            "lamp-off",
            "lamp",
            "language-hiragana",
            "language-katakana",
            "language-off",
            "language",
            "lasso-off",
            "lasso-polygon",
            "lasso",
            "layers-difference",
            "layers-intersect-2",
            "layers-intersect",
            "layers-linked",
            "layers-off",
            "layers-subtract",
            "layers-union",
            "layout-2",
            "layout-align-bottom",
            "layout-align-center",
            "layout-align-left",
            "layout-align-middle",
            "layout-align-right",
            "layout-align-top",
            "layout-board-split",
            "layout-board",
            "layout-bottombar-collapse",
            "layout-bottombar-expand",
            "layout-bottombar",
            "layout-cards",
            "layout-collage",
            "layout-columns",
            "layout-dashboard",
            "layout-distribute-horizontal",
            "layout-distribute-vertical",
            "layout-grid-add",
            "layout-grid",
            "layout-kanban",
            "layout-list",
            "layout-navbar-collapse",
            "layout-navbar-expand",
            "layout-navbar",
            "layout-off",
            "layout-rows",
            "layout-sidebar-left-collapse",
            "layout-sidebar-left-expand",
            "layout-sidebar-right-collapse",
            "layout-sidebar-right-expand",
            "layout-sidebar-right",
            "layout-sidebar",
            "layout",
            "leaf-off",
            "leaf",
            "lego-off",
            "lego",
            "lemon-2",
            "lemon",
            "letter-a",
            "letter-b",
            "letter-c",
            "letter-case-lower",
            "letter-case-toggle",
            "letter-case-upper",
            "letter-case",
            "letter-d",
            "letter-e",
            "letter-f",
            "letter-g",
            "letter-h",
            "letter-i",
            "letter-j",
            "letter-k",
            "letter-l",
            "letter-m",
            "letter-n",
            "letter-o",
            "letter-p",
            "letter-q",
            "letter-r",
            "letter-s",
            "letter-spacing",
            "letter-t",
            "letter-u",
            "letter-v",
            "letter-w",
            "letter-x",
            "letter-y",
            "letter-z",
            "license-off",
            "license",
            "lifebuoy-off",
            "lifebuoy",
            "lighter",
            "line-dashed",
            "line-dotted",
            "line-height",
            "line",
            "link-off",
            "link",
            "list-check",
            "list-details",
            "list-numbers",
            "list-search",
            "list",
            "live-photo-off",
            "live-photo",
            "live-view",
            "loader-2",
            "loader-3",
            "loader-quarter",
            "loader",
            "location-broken",
            "location-filled",
            "location-off",
            "location",
            "lock-access-off",
            "lock-access",
            "lock-bolt",
            "lock-cancel",
            "lock-check",
            "lock-code",
            "lock-cog",
            "lock-dollar",
            "lock-down",
            "lock-exclamation",
            "lock-heart",
            "lock-minus",
            "lock-off",
            "lock-open-off",
            "lock-open",
            "lock-pause",
            "lock-pin",
            "lock-plus",
            "lock-question",
            "lock-search",
            "lock-share",
            "lock-square-rounded-filled",
            "lock-square-rounded",
            "lock-square",
            "lock-star",
            "lock-up",
            "lock-x",
            "lock",
            "logic-and",
            "logic-buffer",
            "logic-nand",
            "logic-nor",
            "logic-not",
            "logic-or",
            "logic-xnor",
            "logic-xor",
            "login",
            "logout",
            "lollipop-off",
            "lollipop",
            "luggage-off",
            "luggage",
            "lungs-off",
            "lungs",
            "macro-off",
            "macro",
            "magnet-off",
            "magnet",
            "mail-ai",
            "mail-bolt",
            "mail-cancel",
            "mail-check",
            "mail-code",
            "mail-cog",
            "mail-dollar",
            "mail-down",
            "mail-exclamation",
            "mail-fast",
            "mail-filled",
            "mail-forward",
            "mail-heart",
            "mail-minus",
            "mail-off",
            "mail-opened-filled",
            "mail-opened",
            "mail-pause",
            "mail-pin",
            "mail-plus",
            "mail-question",
            "mail-search",
            "mail-share",
            "mail-star",
            "mail-up",
            "mail-x",
            "mail",
            "mailbox-off",
            "mailbox",
            "man",
            "manual-gearbox",
            "map-2",
            "map-off",
            "map-pin-bolt",
            "map-pin-cancel",
            "map-pin-check",
            "map-pin-code",
            "map-pin-cog",
            "map-pin-dollar",
            "map-pin-down",
            "map-pin-exclamation",
            "map-pin-filled",
            "map-pin-heart",
            "map-pin-minus",
            "map-pin-off",
            "map-pin-pause",
            "map-pin-pin",
            "map-pin-plus",
            "map-pin-question",
            "map-pin-search",
            "map-pin-share",
            "map-pin-star",
            "map-pin-up",
            "map-pin-x",
            "map-pin",
            "map-pins",
            "map-search",
            "map",
            "markdown-off",
            "markdown",
            "marquee-2",
            "marquee-off",
            "marquee",
            "mars",
            "mask-off",
            "mask",
            "masks-theater-off",
            "masks-theater",
            "massage",
            "matchstick",
            "math-1-divide-2",
            "math-1-divide-3",
            "math-avg",
            "math-equal-greater",
            "math-equal-lower",
            "math-function-off",
            "math-function-y",
            "math-function",
            "math-greater",
            "math-integral-x",
            "math-integral",
            "math-integrals",
            "math-lower",
            "math-max",
            "math-min",
            "math-not",
            "math-off",
            "math-pi-divide-2",
            "math-pi",
            "math-symbols",
            "math-x-divide-2",
            "math-x-divide-y-2",
            "math-x-divide-y",
            "math-x-minus-x",
            "math-x-minus-y",
            "math-x-plus-x",
            "math-x-plus-y",
            "math-xy",
            "math-y-minus-y",
            "math-y-plus-y",
            "math",
            "maximize-off",
            "maximize",
            "meat-off",
            "meat",
            "medal-2",
            "medal",
            "medical-cross-filled",
            "medical-cross-off",
            "medical-cross",
            "medicine-syrup",
            "meeple",
            "menorah",
            "menu-2",
            "menu-order",
            "menu",
            "message-2-bolt",
            "message-2-cancel",
            "message-2-check",
            "message-2-code",
            "message-2-cog",
            "message-2-dollar",
            "message-2-down",
            "message-2-exclamation",
            "message-2-heart",
            "message-2-minus",
            "message-2-off",
            "message-2-pause",
            "message-2-pin",
            "message-2-plus",
            "message-2-question",
            "message-2-search",
            "message-2-share",
            "message-2-star",
            "message-2-up",
            "message-2-x",
            "message-2",
            "message-bolt",
            "message-cancel",
            "message-chatbot",
            "message-check",
            "message-circle-2-filled",
            "message-circle-2",
            "message-circle-bolt",
            "message-circle-cancel",
            "message-circle-check",
            "message-circle-code",
            "message-circle-cog",
            "message-circle-dollar",
            "message-circle-down",
            "message-circle-exclamation",
            "message-circle-heart",
            "message-circle-minus",
            "message-circle-off",
            "message-circle-pause",
            "message-circle-pin",
            "message-circle-plus",
            "message-circle-question",
            "message-circle-search",
            "message-circle-share",
            "message-circle-star",
            "message-circle-up",
            "message-circle-x",
            "message-circle",
            "message-code",
            "message-cog",
            "message-dollar",
            "message-dots",
            "message-down",
            "message-exclamation",
            "message-forward",
            "message-heart",
            "message-language",
            "message-minus",
            "message-off",
            "message-pause",
            "message-pin",
            "message-plus",
            "message-question",
            "message-report",
            "message-search",
            "message-share",
            "message-star",
            "message-up",
            "message-x",
            "message",
            "messages-off",
            "messages",
            "meteor-off",
            "meteor",
            "mickey-filled",
            "mickey",
            "microphone-2-off",
            "microphone-2",
            "microphone-off",
            "microphone",
            "microscope-off",
            "microscope",
            "microwave-off",
            "microwave",
            "military-award",
            "military-rank",
            "milk-off",
            "milk",
            "milkshake",
            "minimize",
            "minus-vertical",
            "minus",
            "mist-off",
            "mist",
            "mobiledata-off",
            "mobiledata",
            "moneybag",
            "mood-angry",
            "mood-annoyed-2",
            "mood-annoyed",
            "mood-boy",
            "mood-check",
            "mood-cog",
            "mood-confuzed-filled",
            "mood-confuzed",
            "mood-crazy-happy",
            "mood-cry",
            "mood-dollar",
            "mood-edit",
            "mood-empty-filled",
            "mood-empty",
            "mood-happy-filled",
            "mood-happy",
            "mood-heart",
            "mood-kid-filled",
            "mood-kid",
            "mood-look-left",
            "mood-look-right",
            "mood-minus",
            "mood-nerd",
            "mood-nervous",
            "mood-neutral-filled",
            "mood-neutral",
            "mood-off",
            "mood-pin",
            "mood-plus",
            "mood-sad-2",
            "mood-sad-dizzy",
            "mood-sad-filled",
            "mood-sad-squint",
            "mood-sad",
            "mood-search",
            "mood-share",
            "mood-sick",
            "mood-silence",
            "mood-sing",
            "mood-smile-beam",
            "mood-smile-dizzy",
            "mood-smile-filled",
            "mood-smile",
            "mood-suprised",
            "mood-tongue-wink-2",
            "mood-tongue-wink",
            "mood-tongue",
            "mood-unamused",
            "mood-up",
            "mood-wink-2",
            "mood-wink",
            "mood-wrrr",
            "mood-x",
            "mood-xd",
            "moon-2",
            "moon-filled",
            "moon-off",
            "moon-stars",
            "moon",
            "moped",
            "motorbike",
            "mountain-off",
            "mountain",
            "mouse-2",
            "mouse-off",
            "mouse",
            "moustache",
            "movie-off",
            "movie",
            "mug-off",
            "mug",
            "multiplier-0-5x",
            "multiplier-1-5x",
            "multiplier-1x",
            "multiplier-2x",
            "mushroom-filled",
            "mushroom-off",
            "mushroom",
            "music-off",
            "music",
            "navigation-filled",
            "navigation-off",
            "navigation",
            "needle-thread",
            "needle",
            "network-off",
            "network",
            "new-section",
            "news-off",
            "news",
            "nfc-off",
            "nfc",
            "no-copyright",
            "no-creative-commons",
            "no-derivatives",
            "north-star",
            "note-off",
            "note",
            "notebook-off",
            "notebook",
            "notes-off",
            "notes",
            "notification-off",
            "notification",
            "number-0",
            "number-1",
            "number-2",
            "number-3",
            "number-4",
            "number-5",
            "number-6",
            "number-7",
            "number-8",
            "number-9",
            "number",
            "numbers",
            "nurse",
            "octagon-filled",
            "octagon-off",
            "octagon",
            "old",
            "olympics-off",
            "olympics",
            "om",
            "omega",
            "outbound",
            "outlet",
            "oval-filled",
            "oval-vertical-filled",
            "oval-vertical",
            "oval",
            "overline",
            "package-export",
            "package-import",
            "package-off",
            "package",
            "packages",
            "pacman",
            "page-break",
            "paint-filled",
            "paint-off",
            "paint",
            "palette-off",
            "palette",
            "panorama-horizontal-off",
            "panorama-horizontal",
            "panorama-vertical-off",
            "panorama-vertical",
            "paper-bag-off",
            "paper-bag",
            "paperclip",
            "parachute-off",
            "parachute",
            "parentheses-off",
            "parentheses",
            "parking-off",
            "parking",
            "password",
            "paw-filled",
            "paw-off",
            "paw",
            "pdf",
            "peace",
            "pencil-minus",
            "pencil-off",
            "pencil-plus",
            "pencil",
            "pennant-2-filled",
            "pennant-2",
            "pennant-filled",
            "pennant-off",
            "pennant",
            "pentagon-filled",
            "pentagon-off",
            "pentagon",
            "pentagram",
            "pepper-off",
            "pepper",
            "percentage",
            "perfume",
            "perspective-off",
            "perspective",
            "phone-call",
            "phone-calling",
            "phone-check",
            "phone-filled",
            "phone-incoming",
            "phone-off",
            "phone-outgoing",
            "phone-pause",
            "phone-plus",
            "phone-x",
            "phone",
            "photo-ai",
            "photo-bolt",
            "photo-cancel",
            "photo-check",
            "photo-code",
            "photo-cog",
            "photo-dollar",
            "photo-down",
            "photo-edit",
            "photo-exclamation",
            "photo-filled",
            "photo-heart",
            "photo-minus",
            "photo-off",
            "photo-pause",
            "photo-pin",
            "photo-plus",
            "photo-question",
            "photo-search",
            "photo-sensor-2",
            "photo-sensor-3",
            "photo-sensor",
            "photo-share",
            "photo-shield",
            "photo-star",
            "photo-up",
            "photo-x",
            "photo",
            "physotherapist",
            "picture-in-picture-off",
            "picture-in-picture-on",
            "picture-in-picture-top",
            "picture-in-picture",
            "pig-money",
            "pig-off",
            "pig",
            "pilcrow",
            "pill-off",
            "pill",
            "pills",
            "pin-filled",
            "pin",
            "ping-pong",
            "pinned-filled",
            "pinned-off",
            "pinned",
            "pizza-off",
            "pizza",
            "placeholder",
            "plane-arrival",
            "plane-departure",
            "plane-inflight",
            "plane-off",
            "plane-tilt",
            "plane",
            "planet-off",
            "planet",
            "plant-2-off",
            "plant-2",
            "plant-off",
            "plant",
            "play-card-off",
            "play-card",
            "player-eject-filled",
            "player-eject",
            "player-pause-filled",
            "player-pause",
            "player-play-filled",
            "player-play",
            "player-record-filled",
            "player-record",
            "player-skip-back-filled",
            "player-skip-back",
            "player-skip-forward-filled",
            "player-skip-forward",
            "player-stop-filled",
            "player-stop",
            "player-track-next-filled",
            "player-track-next",
            "player-track-prev-filled",
            "player-track-prev",
            "playlist-add",
            "playlist-off",
            "playlist-x",
            "playlist",
            "playstation-circle",
            "playstation-square",
            "playstation-triangle",
            "playstation-x",
            "plug-connected-x",
            "plug-connected",
            "plug-off",
            "plug-x",
            "plug",
            "plus-equal",
            "plus-minus",
            "plus",
            "png",
            "podium-off",
            "podium",
            "point-filled",
            "point-off",
            "point",
            "pointer-bolt",
            "pointer-cancel",
            "pointer-check",
            "pointer-code",
            "pointer-cog",
            "pointer-dollar",
            "pointer-down",
            "pointer-exclamation",
            "pointer-heart",
            "pointer-minus",
            "pointer-off",
            "pointer-pause",
            "pointer-pin",
            "pointer-plus",
            "pointer-question",
            "pointer-search",
            "pointer-share",
            "pointer-star",
            "pointer-up",
            "pointer-x",
            "pointer",
            "pokeball-off",
            "pokeball",
            "poker-chip",
            "polaroid-filled",
            "polaroid",
            "polygon-off",
            "polygon",
            "poo",
            "pool-off",
            "pool",
            "power",
            "pray",
            "premium-rights",
            "prescription",
            "presentation-analytics",
            "presentation-off",
            "presentation",
            "printer-off",
            "printer",
            "prison",
            "progress-alert",
            "progress-bolt",
            "progress-check",
            "progress-down",
            "progress-help",
            "progress-x",
            "progress",
            "prompt",
            "propeller-off",
            "propeller",
            "pumpkin-scary",
            "puzzle-2",
            "puzzle-filled",
            "puzzle-off",
            "puzzle",
            "pyramid-off",
            "pyramid",
            "qrcode-off",
            "qrcode",
            "question-mark",
            "quote-off",
            "quote",
            "radar-2",
            "radar-off",
            "radar",
            "radio-off",
            "radio",
            "radioactive-filled",
            "radioactive-off",
            "radioactive",
            "radius-bottom-left",
            "radius-bottom-right",
            "radius-top-left",
            "radius-top-right",
            "rainbow-off",
            "rainbow",
            "rating-12-plus",
            "rating-14-plus",
            "rating-16-plus",
            "rating-18-plus",
            "rating-21-plus",
            "razor-electric",
            "razor",
            "receipt-2",
            "receipt-off",
            "receipt-refund",
            "receipt-tax",
            "receipt",
            "recharging",
            "record-mail-off",
            "record-mail",
            "rectangle-filled",
            "rectangle-vertical-filled",
            "rectangle-vertical",
            "rectangle",
            "recycle-off",
            "recycle",
            "refresh-alert",
            "refresh-dot",
            "refresh-off",
            "refresh",
            "regex-off",
            "regex",
            "registered",
            "relation-many-to-many",
            "relation-one-to-many",
            "relation-one-to-one",
            "reload",
            "repeat-off",
            "repeat-once",
            "repeat",
            "replace-filled",
            "replace-off",
            "replace",
            "report-analytics",
            "report-medical",
            "report-money",
            "report-off",
            "report-search",
            "report",
            "reserved-line",
            "resize",
            "ribbon-health",
            "ripple-off",
            "ripple",
            "road-off",
            "road-sign",
            "road",
            "robot-off",
            "robot",
            "rocket-off",
            "rocket",
            "roller-skating",
            "rollercoaster-off",
            "rollercoaster",
            "rosette-filled",
            "rosette-number-0",
            "rosette-number-1",
            "rosette-number-2",
            "rosette-number-3",
            "rosette-number-4",
            "rosette-number-5",
            "rosette-number-6",
            "rosette-number-7",
            "rosette-number-8",
            "rosette-number-9",
            "rosette",
            "rotate-2",
            "rotate-360",
            "rotate-clockwise-2",
            "rotate-clockwise",
            "rotate-dot",
            "rotate-rectangle",
            "rotate",
            "route-2",
            "route-off",
            "route",
            "router-off",
            "router",
            "row-insert-bottom",
            "row-insert-top",
            "rss",
            "rubber-stamp-off",
            "rubber-stamp",
            "ruler-2-off",
            "ruler-2",
            "ruler-3",
            "ruler-measure",
            "ruler-off",
            "ruler",
            "run",
            "s-turn-down",
            "s-turn-left",
            "s-turn-right",
            "s-turn-up",
            "sailboat-2",
            "sailboat-off",
            "sailboat",
            "salad",
            "salt",
            "satellite-off",
            "satellite",
            "sausage",
            "scale-off",
            "scale-outline-off",
            "scale-outline",
            "scale",
            "scan-eye",
            "scan",
            "schema-off",
            "schema",
            "school-bell",
            "school-off",
            "school",
            "scissors-off",
            "scissors",
            "scooter-electric",
            "scooter",
            "screen-share-off",
            "screen-share",
            "screenshot",
            "scribble-off",
            "scribble",
            "script-minus",
            "script-plus",
            "script-x",
            "script",
            "scuba-mask-off",
            "scuba-mask",
            "sdk",
            "search-off",
            "search",
            "section-sign",
            "section",
            "seeding-off",
            "seeding",
            "select-all",
            "select",
            "selector",
            "send-off",
            "send",
            "seo",
            "separator-horizontal",
            "separator-vertical",
            "separator",
            "server-2",
            "server-bolt",
            "server-cog",
            "server-off",
            "server",
            "servicemark",
            "settings-2",
            "settings-automation",
            "settings-bolt",
            "settings-cancel",
            "settings-check",
            "settings-code",
            "settings-cog",
            "settings-dollar",
            "settings-down",
            "settings-exclamation",
            "settings-filled",
            "settings-heart",
            "settings-minus",
            "settings-off",
            "settings-pause",
            "settings-pin",
            "settings-plus",
            "settings-question",
            "settings-search",
            "settings-share",
            "settings-star",
            "settings-up",
            "settings-x",
            "settings",
            "shadow-off",
            "shadow",
            "shape-2",
            "shape-3",
            "shape-off",
            "shape",
            "share-2",
            "share-3",
            "share-off",
            "share",
            "shield-bolt",
            "shield-cancel",
            "shield-check-filled",
            "shield-check",
            "shield-checkered-filled",
            "shield-checkered",
            "shield-chevron",
            "shield-code",
            "shield-cog",
            "shield-dollar",
            "shield-down",
            "shield-exclamation",
            "shield-filled",
            "shield-half-filled",
            "shield-half",
            "shield-heart",
            "shield-lock-filled",
            "shield-lock",
            "shield-minus",
            "shield-off",
            "shield-pause",
            "shield-pin",
            "shield-plus",
            "shield-question",
            "shield-search",
            "shield-share",
            "shield-star",
            "shield-up",
            "shield-x",
            "shield",
            "ship-off",
            "ship",
            "shirt-filled",
            "shirt-off",
            "shirt-sport",
            "shirt",
            "shoe-off",
            "shoe",
            "shopping-bag",
            "shopping-cart-discount",
            "shopping-cart-off",
            "shopping-cart-plus",
            "shopping-cart-x",
            "shopping-cart",
            "shovel",
            "shredder",
            "sign-left-filled",
            "sign-left",
            "sign-right-filled",
            "sign-right",
            "signal-2g",
            "signal-3g",
            "signal-4g-plus",
            "signal-4g",
            "signal-5g",
            "signal-6g",
            "signal-e",
            "signal-g",
            "signal-h-plus",
            "signal-h",
            "signal-lte",
            "signature-off",
            "signature",
            "sitemap-off",
            "sitemap",
            "skateboard-off",
            "skateboard",
            "skull",
            "slash",
            "slashes",
            "sleigh",
            "slice",
            "slideshow",
            "smart-home-off",
            "smart-home",
            "smoking-no",
            "smoking",
            "snowflake-off",
            "snowflake",
            "snowman",
            "soccer-field",
            "social-off",
            "social",
            "sock",
            "sofa-off",
            "sofa",
            "solar-panel-2",
            "solar-panel",
            "sort-0-9",
            "sort-9-0",
            "sort-a-z",
            "sort-ascending-2",
            "sort-ascending-letters",
            "sort-ascending-numbers",
            "sort-ascending",
            "sort-descending-2",
            "sort-descending-letters",
            "sort-descending-numbers",
            "sort-descending",
            "sort-z-a",
            "sos",
            "soup-off",
            "soup",
            "source-code",
            "space-off",
            "space",
            "spacing-horizontal",
            "spacing-vertical",
            "spade-filled",
            "spade",
            "sparkles",
            "speakerphone",
            "speedboat",
            "spider",
            "spiral-off",
            "spiral",
            "sport-billard",
            "spray",
            "spy-off",
            "spy",
            "sql",
            "square-0-filled",
            "square-1-filled",
            "square-2-filled",
            "square-3-filled",
            "square-4-filled",
            "square-5-filled",
            "square-6-filled",
            "square-7-filled",
            "square-8-filled",
            "square-9-filled",
            "square-arrow-down",
            "square-arrow-left",
            "square-arrow-right",
            "square-arrow-up",
            "square-asterisk",
            "square-check-filled",
            "square-check",
            "square-chevron-down",
            "square-chevron-left",
            "square-chevron-right",
            "square-chevron-up",
            "square-chevrons-down",
            "square-chevrons-left",
            "square-chevrons-right",
            "square-chevrons-up",
            "square-dot",
            "square-f0-filled",
            "square-f0",
            "square-f1-filled",
            "square-f1",
            "square-f2-filled",
            "square-f2",
            "square-f3-filled",
            "square-f3",
            "square-f4-filled",
            "square-f4",
            "square-f5-filled",
            "square-f5",
            "square-f6-filled",
            "square-f6",
            "square-f7-filled",
            "square-f7",
            "square-f8-filled",
            "square-f8",
            "square-f9-filled",
            "square-f9",
            "square-forbid-2",
            "square-forbid",
            "square-half",
            "square-key",
            "square-letter-a",
            "square-letter-b",
            "square-letter-c",
            "square-letter-d",
            "square-letter-e",
            "square-letter-f",
            "square-letter-g",
            "square-letter-h",
            "square-letter-i",
            "square-letter-j",
            "square-letter-k",
            "square-letter-l",
            "square-letter-m",
            "square-letter-n",
            "square-letter-o",
            "square-letter-p",
            "square-letter-q",
            "square-letter-r",
            "square-letter-s",
            "square-letter-t",
            "square-letter-u",
            "square-letter-v",
            "square-letter-w",
            "square-letter-x",
            "square-letter-y",
            "square-letter-z",
            "square-minus",
            "square-number-0",
            "square-number-1",
            "square-number-2",
            "square-number-3",
            "square-number-4",
            "square-number-5",
            "square-number-6",
            "square-number-7",
            "square-number-8",
            "square-number-9",
            "square-off",
            "square-plus",
            "square-root-2",
            "square-root",
            "square-rotated-filled",
            "square-rotated-forbid-2",
            "square-rotated-forbid",
            "square-rotated-off",
            "square-rotated",
            "square-rounded-arrow-down-filled",
            "square-rounded-arrow-down",
            "square-rounded-arrow-left-filled",
            "square-rounded-arrow-left",
            "square-rounded-arrow-right-filled",
            "square-rounded-arrow-right",
            "square-rounded-arrow-up-filled",
            "square-rounded-arrow-up",
            "square-rounded-check-filled",
            "square-rounded-check",
            "square-rounded-chevron-down-filled",
            "square-rounded-chevron-down",
            "square-rounded-chevron-left-filled",
            "square-rounded-chevron-left",
            "square-rounded-chevron-right-filled",
            "square-rounded-chevron-right",
            "square-rounded-chevron-up-filled",
            "square-rounded-chevron-up",
            "square-rounded-chevrons-down-filled",
            "square-rounded-chevrons-down",
            "square-rounded-chevrons-left-filled",
            "square-rounded-chevrons-left",
            "square-rounded-chevrons-right-filled",
            "square-rounded-chevrons-right",
            "square-rounded-chevrons-up-filled",
            "square-rounded-chevrons-up",
            "square-rounded-filled",
            "square-rounded-letter-a",
            "square-rounded-letter-b",
            "square-rounded-letter-c",
            "square-rounded-letter-d",
            "square-rounded-letter-e",
            "square-rounded-letter-f",
            "square-rounded-letter-g",
            "square-rounded-letter-h",
            "square-rounded-letter-i",
            "square-rounded-letter-j",
            "square-rounded-letter-k",
            "square-rounded-letter-l",
            "square-rounded-letter-m",
            "square-rounded-letter-n",
            "square-rounded-letter-o",
            "square-rounded-letter-p",
            "square-rounded-letter-q",
            "square-rounded-letter-r",
            "square-rounded-letter-s",
            "square-rounded-letter-t",
            "square-rounded-letter-u",
            "square-rounded-letter-v",
            "square-rounded-letter-w",
            "square-rounded-letter-x",
            "square-rounded-letter-y",
            "square-rounded-letter-z",
            "square-rounded-minus",
            "square-rounded-number-0-filled",
            "square-rounded-number-0",
            "square-rounded-number-1-filled",
            "square-rounded-number-1",
            "square-rounded-number-2-filled",
            "square-rounded-number-2",
            "square-rounded-number-3-filled",
            "square-rounded-number-3",
            "square-rounded-number-4-filled",
            "square-rounded-number-4",
            "square-rounded-number-5-filled",
            "square-rounded-number-5",
            "square-rounded-number-6-filled",
            "square-rounded-number-6",
            "square-rounded-number-7-filled",
            "square-rounded-number-7",
            "square-rounded-number-8-filled",
            "square-rounded-number-8",
            "square-rounded-number-9-filled",
            "square-rounded-number-9",
            "square-rounded-plus-filled",
            "square-rounded-plus",
            "square-rounded-x-filled",
            "square-rounded-x",
            "square-rounded",
            "square-toggle-horizontal",
            "square-toggle",
            "square-x",
            "square",
            "squares-diagonal",
            "squares-filled",
            "stack-2",
            "stack-3",
            "stack-pop",
            "stack-push",
            "stack",
            "stairs-down",
            "stairs-up",
            "stairs",
            "star-filled",
            "star-half-filled",
            "star-half",
            "star-off",
            "star",
            "stars-filled",
            "stars-off",
            "stars",
            "status-change",
            "steam",
            "steering-wheel-off",
            "steering-wheel",
            "step-into",
            "step-out",
            "stereo-glasses",
            "stethoscope-off",
            "stethoscope",
            "sticker",
            "storm-off",
            "storm",
            "stretching",
            "strikethrough",
            "submarine",
            "subscript",
            "subtask",
            "sum-off",
            "sum",
            "sun-filled",
            "sun-high",
            "sun-low",
            "sun-moon",
            "sun-off",
            "sun-wind",
            "sun",
            "sunglasses",
            "sunrise",
            "sunset-2",
            "sunset",
            "superscript",
            "svg",
            "swimming",
            "swipe",
            "switch-2",
            "switch-3",
            "switch-horizontal",
            "switch-vertical",
            "switch",
            "sword-off",
            "sword",
            "swords",
            "table-alias",
            "table-down",
            "table-export",
            "table-filled",
            "table-heart",
            "table-import",
            "table-minus",
            "table-off",
            "table-options",
            "table-plus",
            "table-share",
            "table-shortcut",
            "table",
            "tag-off",
            "tag",
            "tags-off",
            "tags",
            "tallymark-1",
            "tallymark-2",
            "tallymark-3",
            "tallymark-4",
            "tallymarks",
            "tank",
            "target-arrow",
            "target-off",
            "target",
            "teapot",
            "telescope-off",
            "telescope",
            "temperature-celsius",
            "temperature-fahrenheit",
            "temperature-minus",
            "temperature-off",
            "temperature-plus",
            "temperature",
            "template-off",
            "template",
            "tent-off",
            "tent",
            "terminal-2",
            "terminal",
            "test-pipe-2",
            "test-pipe-off",
            "test-pipe",
            "tex",
            "text-caption",
            "text-color",
            "text-decrease",
            "text-direction-ltr",
            "text-direction-rtl",
            "text-increase",
            "text-orientation",
            "text-plus",
            "text-recognition",
            "text-resize",
            "text-size",
            "text-spellcheck",
            "text-wrap-disabled",
            "text-wrap",
            "texture",
            "theater",
            "thermometer",
            "thumb-down-filled",
            "thumb-down-off",
            "thumb-down",
            "thumb-up-filled",
            "thumb-up-off",
            "thumb-up",
            "tic-tac",
            "ticket-off",
            "ticket",
            "tie",
            "tilde",
            "tilt-shift-off",
            "tilt-shift",
            "timeline-event-exclamation",
            "timeline-event-minus",
            "timeline-event-plus",
            "timeline-event-text",
            "timeline-event-x",
            "timeline-event",
            "timeline",
            "tir",
            "toggle-left",
            "toggle-right",
            "toilet-paper-off",
            "toilet-paper",
            "tool",
            "tools-kitchen-2-off",
            "tools-kitchen-2",
            "tools-kitchen-off",
            "tools-kitchen",
            "tools-off",
            "tools",
            "tooltip",
            "topology-bus",
            "topology-complex",
            "topology-full-hierarchy",
            "topology-full",
            "topology-ring-2",
            "topology-ring-3",
            "topology-ring",
            "topology-star-2",
            "topology-star-3",
            "topology-star-ring-2",
            "topology-star-ring-3",
            "topology-star-ring",
            "topology-star",
            "torii",
            "tornado",
            "tournament",
            "tower-off",
            "tower",
            "track",
            "tractor",
            "trademark",
            "traffic-cone-off",
            "traffic-cone",
            "traffic-lights-off",
            "traffic-lights",
            "train",
            "transfer-in",
            "transfer-out",
            "transform-filled",
            "transform",
            "transition-bottom",
            "transition-left",
            "transition-right",
            "transition-top",
            "trash-filled",
            "trash-off",
            "trash-x-filled",
            "trash-x",
            "trash",
            "tree",
            "trees",
            "trekking",
            "trending-down-2",
            "trending-down-3",
            "trending-down",
            "trending-up-2",
            "trending-up-3",
            "trending-up",
            "triangle-filled",
            "triangle-inverted-filled",
            "triangle-inverted",
            "triangle-off",
            "triangle-square-circle",
            "triangle",
            "triangles",
            "trident",
            "trolley",
            "trophy-filled",
            "trophy-off",
            "trophy",
            "trowel",
            "truck-delivery",
            "truck-loading",
            "truck-off",
            "truck-return",
            "truck",
            "txt",
            "typography-off",
            "typography",
            "ufo-off",
            "ufo",
            "umbrella-filled",
            "umbrella-off",
            "umbrella",
            "underline",
            "unlink",
            "upload",
            "urgent",
            "usb",
            "user-bolt",
            "user-cancel",
            "user-check",
            "user-circle",
            "user-code",
            "user-cog",
            "user-dollar",
            "user-down",
            "user-edit",
            "user-exclamation",
            "user-heart",
            "user-minus",
            "user-off",
            "user-pause",
            "user-pin",
            "user-plus",
            "user-question",
            "user-search",
            "user-share",
            "user-shield",
            "user-star",
            "user-up",
            "user-x",
            "user",
            "users-group",
            "users-minus",
            "users-plus",
            "users",
            "uv-index",
            "ux-circle",
            "vaccine-bottle-off",
            "vaccine-bottle",
            "vaccine-off",
            "vaccine",
            "vacuum-cleaner",
            "variable-minus",
            "variable-off",
            "variable-plus",
            "variable",
            "vector-bezier-2",
            "vector-bezier-arc",
            "vector-bezier-circle",
            "vector-bezier",
            "vector-off",
            "vector-spline",
            "vector-triangle-off",
            "vector-triangle",
            "vector",
            "venus",
            "versions-filled",
            "versions-off",
            "versions",
            "video-minus",
            "video-off",
            "video-plus",
            "video",
            "view-360-off",
            "view-360",
            "viewfinder-off",
            "viewfinder",
            "viewport-narrow",
            "viewport-wide",
            "vinyl",
            "vip-off",
            "vip",
            "virus-off",
            "virus-search",
            "virus",
            "vocabulary-off",
            "vocabulary",
            "volcano",
            "volume-2",
            "volume-3",
            "volume-off",
            "volume",
            "walk",
            "wall-off",
            "wall",
            "wallet-off",
            "wallet",
            "wallpaper-off",
            "wallpaper",
            "wand-off",
            "wand",
            "wash-dry-1",
            "wash-dry-2",
            "wash-dry-3",
            "wash-dry-a",
            "wash-dry-dip",
            "wash-dry-f",
            "wash-dry-hang",
            "wash-dry-off",
            "wash-dry-p",
            "wash-dry-shade",
            "wash-dry-w",
            "wash-dry",
            "wash-dryclean-off",
            "wash-dryclean",
            "wash-gentle",
            "wash-machine",
            "wash-off",
            "wash-press",
            "wash-temperature-1",
            "wash-temperature-2",
            "wash-temperature-3",
            "wash-temperature-4",
            "wash-temperature-5",
            "wash-temperature-6",
            "wash-tumble-dry",
            "wash-tumble-off",
            "wash",
            "wave-saw-tool",
            "wave-sine",
            "wave-square",
            "webhook-off",
            "webhook",
            "weight",
            "wheelchair-off",
            "wheelchair",
            "whirl",
            "wifi-0",
            "wifi-1",
            "wifi-2",
            "wifi-off",
            "wifi",
            "wind-off",
            "wind",
            "windmill-filled",
            "windmill-off",
            "windmill",
            "window-maximize",
            "window-minimize",
            "window-off",
            "window",
            "windsock",
            "wiper-wash",
            "wiper",
            "woman",
            "wood",
            "world-bolt",
            "world-cancel",
            "world-check",
            "world-code",
            "world-cog",
            "world-dollar",
            "world-down",
            "world-download",
            "world-exclamation",
            "world-heart",
            "world-latitude",
            "world-longitude",
            "world-minus",
            "world-off",
            "world-pause",
            "world-pin",
            "world-plus",
            "world-question",
            "world-search",
            "world-share",
            "world-star",
            "world-up",
            "world-upload",
            "world-www",
            "world-x",
            "world",
            "wrecking-ball",
            "writing-off",
            "writing-sign-off",
            "writing-sign",
            "writing",
            "x",
            "xbox-a",
            "xbox-b",
            "xbox-x",
            "xbox-y",
            "xd",
            "yin-yang-filled",
            "yin-yang",
            "yoga",
            "zeppelin-off",
            "zeppelin",
            "zip",
            "zodiac-aquarius",
            "zodiac-aries",
            "zodiac-cancer",
            "zodiac-capricorn",
            "zodiac-gemini",
            "zodiac-leo",
            "zodiac-libra",
            "zodiac-pisces",
            "zodiac-sagittarius",
            "zodiac-scorpio",
            "zodiac-taurus",
            "zodiac-virgo",
            "zoom-cancel",
            "zoom-check-filled",
            "zoom-check",
            "zoom-code",
            "zoom-exclamation",
            "zoom-filled",
            "zoom-in-area-filled",
            "zoom-in-area",
            "zoom-in-filled",
            "zoom-in",
            "zoom-money",
            "zoom-out-area",
            "zoom-out-filled",
            "zoom-out",
            "zoom-pan",
            "zoom-question",
            "zoom-replace",
            "zoom-reset",
            "zzz-off",
            "zzz",
        ],
    },
    color: {
        type: ControlType.Color,
        defaultValue: TablerIcons.defaultProps.color,
        title: "Color",
    },
    strokeWidth: {
        type: ControlType.Number,
        defaultValue: TablerIcons.defaultProps.strokeWidth,
        title: "Stroke",
        step: 0.1,
        displayStepper: true,
    },
})
